import * as React from "react";
import {
    Create, SimpleForm, ReferenceInput, AutocompleteInput
} from 'react-admin';
import { Box } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import { RichTextSmallToolbar } from '../../layout/RichTextSmallToolbar';

const redirect = (basePath, id, data) => `clients/${data.client_id}/show/2`;

export const ClientNotesCreate = () => {

    return (
        <Create redirect={redirect}>
            <SimpleForm variant="standard" >
                <ReferenceInput source="client_id" reference="clients"
                    filterToQuery={searchText => ({ name: searchText })} 
                    // enableGetChoices={({ q }) => q?.length >= 2}
                    perPage={100} >
                    <AutocompleteInput label="Nome do cliente" optionText="name" />
                </ReferenceInput>

                <Box sx={{
                    width: '100%',
                    display: { xs: 'flex', sm: 'flex', lg: 'none', xl: 'none' },
                }} >
                    <RichTextSmallToolbar size={'small'} label={false} source="note" fullWidth helperText="Descrição da nota" />
                </Box>

                <Box sx={{
                    width: '100%',
                    display: { xs: 'none', sm: 'none', lg: 'inline', xl: 'inline' },
                    wordBreak: "break-word",
                }} >
                    <RichTextInput label={false} source="note" fullWidth helperText="Descrição da nota" />
                </Box>
            </SimpleForm>
        </Create>
    )
};