import {
    EditButton, TopToolbar, ListButton, ShowButton, CreateButton, ExportButton, FilterButton, SortButton, CloneButton
} from 'react-admin';

export const EditActions = ({ data }) => (
    <TopToolbar>
        <ListButton  />
        <ShowButton  record={data} />
    </TopToolbar>
);

export const ListActions = (props) => (
    <TopToolbar>
        <SortButton fields={['name', 'industry', 'status',
            'created_at', 'updated_at', 'objective__name', "client_type__name"]} />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export const ShowActions = ({  data, resource }) => (
    <TopToolbar>
        <CloneButton />
        <EditButton  record={data} />
        <ListButton  />
    </TopToolbar>
);