import * as React from "react";
import {
    Edit, SimpleForm, ReferenceInput, DateInput, AutocompleteInput,
} from 'react-admin';
import { Box } from '@mui/material';
import EditCreateToolbar from "../EditCreateToolbar";
import validateNotesCreation from "./ClientNotesValidation"
import { RichTextInput } from 'ra-input-rich-text';
import { RichTextSmallToolbar } from '../../layout/RichTextSmallToolbar';

import { EditActions } from "../Actions"
const redirect = (basePath, id, data) => `clients/${data.client_id}/show/2`;

export const ClientNotesEdit = props => {

    return (
        <Edit actions={<EditActions />}  {...props} redirect={redirect}>
            <SimpleForm variant="standard" toolbar={<EditCreateToolbar />} validate={validateNotesCreation} >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    columnGap: '1rem',
                }}>
                    <ReferenceInput source="client_id" reference="clients"
                        filterToQuery={searchText => ({ name: searchText })} 
                        // enableGetChoices={({ q }) => q?.length >= 2}
                        perPage={100} >
                        <AutocompleteInput label="Nome do cliente" optionText="name" />
                    </ReferenceInput>
                    <DateInput label="Criado em" disabled source="created_at" sx={{ maxWidth: '9rem' }} />
                    < DateInput label="Atualizado em" disabled source="updated_at" sx={{ maxWidth: '9rem' }} />
                </Box>
                <Box sx={{
                    width: '100%',
                    display: { xs: 'flex', sm: 'flex', lg: 'none', xl: 'none' },
                }} >
                    <RichTextSmallToolbar size={'small'} label={false} source="note" fullWidth helperText="Descrição da nota" />
                </Box>

                <Box sx={{
                    width: '100%',
                    display: { xs: 'none', sm: 'none', lg: 'inline', xl: 'inline' },
                    wordBreak: "break-word",
                }} >
                    <RichTextInput label={false} source="note" fullWidth helperText="Descrição da nota" />
                </Box>
            </SimpleForm>
        </Edit>
    )
};