const validateNotesCreation = (values) => {
    const errors = {};
    if (!values.client_id) {
        errors.client_id = 'ra.validation.required';
    }
    if (values.note !== undefined && values.note.length < 5) {
        errors.note = {
            message: 'ra.validation.minLength',
            args: { min: 5 }            
        };
    }
    return errors
};

export default validateNotesCreation