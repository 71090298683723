import * as React from "react";
import {
    List, Datagrid, EditButton, TextInput, CloneButton, DeleteButton, SimpleList, TextField
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { ColoredChipField } from "../ColoredChips.tsx";
import { ListActions } from "../Actions"

const postFilters = [
    <TextInput label="Busca por nome" source="name" alwaysOn variant="standard" />,
    <TextInput label="Descrição" source="description" variant="standard" />,
];

export const JobTypeModelList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List actions={<ListActions />} filters={postFilters} {...props} empty={false} >
                {isSmall ? (
            <SimpleList linkType="show"
            primaryText={record => record.name}
            secondaryText={<TextField sx={{
                display: "block",
                maxWidth: "35ch",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
                label="Descrição" source="description" />}
            // tertiaryText={record => (record.id)}
        />
        ) : (
        <Datagrid rowClick="show">
            {/* <TextField source="id" /> */}
            <ColoredChipField source="name" label="Nome" />
            <TextField sx={{
                display: "block",
                maxWidth: "75ch",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
                label="Descrição" source="description" />           
            <EditButton label="" />
            <CloneButton label="" />
            <DeleteButton label="" />
        </Datagrid>
   )}
   </List>
   );
       }