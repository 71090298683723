const validateCreditEmailCreation = (values) => {
    const errors = {};
    if (!values.client_id) {
        errors.client_id = 'ra.validation.required';
    }
    if (!values.template_id) {
        errors.value = 'ra.validation.required';
    }
    return errors
};

export default validateCreditEmailCreation