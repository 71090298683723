import * as React from "react";
import {
    Edit, SimpleForm, TextInput, required, maxLength
} from 'react-admin';
import EditCreateToolbar from "../EditCreateToolbar";

import { Box } from '@mui/material';
import { EditActions } from "../Actions"


export const JobTypeModelEdit = () => {

    return (
        <Edit actions={<EditActions />} >
            <SimpleForm variant="standard" toolbar={<EditCreateToolbar />} >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    columnGap: '1rem',
                }}>
                    <TextInput disabled source="id" sx={{ maxWidth: '5rem' }} />
                    <TextInput label="Nome" source="name"  validate={[required(), maxLength(60)]} />
                    <TextInput label="Cor" source="color"  validate={[required(), maxLength(10)]} />
                </Box>
                <TextInput label="Descrição" fullWidth source="description" />
            </SimpleForm>
        </Edit>
    )
};