import * as React from "react";
import {
    List, Datagrid, ArrayField, TextField, SingleFieldList, DateField, ReferenceField, BooleanField, TextInput,
    FunctionField, EditButton, CloneButton, DeleteButton, CreateButton, ExportButton, SortButton, FilterButton,
    DateInput, BooleanInput, ReferenceArrayInput, SelectArrayInput, TopToolbar, SimpleList
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { ColoredChipField } from "../ColoredChips.tsx";
import RecordPagination from "../RecordPagination";

const ListActions = () => (
    <TopToolbar>
        <SortButton fields={["value", "start_date", "expected_pay_date", "description", "created_at", "costs"]} label="Ordenar" />
        <FilterButton label="Filtrar" />
        <CreateButton label="ra.action.create_item" />
        <ExportButton label="Exportar" />
    </TopToolbar>
);

const postFilters = [
    <TextInput source="for_client" alwaysOn label="Busca por cliente" variant="standard" />,
    <BooleanInput source="with_nfe" label="NFe Emitida" />,
    // <TextInput label="Tipo de Trabalho" source="job_type" defaultValue="Google Ads" />,
    <TextInput label="Conta de Pagamento" source="pay_account" defaultValue="Nubank" variant="standard" />,
    <TextInput label="Status do trabalho" source="status" defaultValue="Iniciado" variant="standard" />,
    <TextInput label="Descrição" source="description" variant="standard" />,
    <DateInput label="Data inicial | De" source="job_start_date_start" variant="standard" />,
    <DateInput label="Data inicial | Até " source="job_start_date_end" variant="standard" />,
    <DateInput label="Data NFe | De" source="job_nfe_date_start" variant="standard" />,
    <DateInput label="Data NFe | Até " source="job_nfe_date_end" variant="standard" />,
    <DateInput label="Vencimento | De" source="expected_pay_date_start" variant="standard" />,
    <DateInput label="Vencimento | Até " source="expected_pay_date_end" variant="standard" />,
    <FunctionField source="pay_date" label="Pago" render={(record, source) =>
        <BooleanInput record={{ ...record, pay_date: !!record.pay_date }} source={source} />} />,
    <ReferenceArrayInput source="job_type" reference="job_type" label="Tipos de trabalho" variant="standard" >
        <SelectArrayInput allowEmpty optionText="name" optionValue="id" variant="standard" autoWidth="true" label="Tipos de trabalho" >
        </SelectArrayInput>
    </ReferenceArrayInput>
];

export const JobList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isReallyBig = useMediaQuery('(min-width:1921px)')
    return (
        <List actions={<ListActions />} filters={postFilters} {...props} sort={{ field: 'expected_pay_date', order: 'DESC' }} empty={false}
            pagination={<RecordPagination />} perPage={15} >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => <ReferenceField label="Name" source="client_id" reference="clients" basePath="clients"
                        record={record} link="show">
                        <TextField source="name" />
                    </ReferenceField>}
                    secondaryText={record => `R$${record.value.toLocaleString('pt-br')}`}
                    // tertiaryText={record => new Date(record.start_date).toLocaleDateString('pt-br') }
                    tertiaryText={record => <DateField source="start_date" label="Início" sortBy="start_date" sortByOrder="DESC" locales="pt-br" options={{ timeZone: 'UTC' }} />}
                />
            ) :
                isReallyBig ? (
                    <Datagrid rowClick="show" >
                        {/* <TextField source="id" /> */}
                        <ReferenceField label="Cliente" source="client_id" reference="clients" ><TextField source="name" /></ReferenceField>
                        <DateField source="start_date" label="Início" sortBy="start_date" sortByOrder="DESC" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        <DateField source="expected_pay_date" locales="pt-br" options={{ timeZone: 'UTC' }} />

                        <FunctionField source="pay_date" label="Pago" render={(record, source) =>
                            <BooleanField record={{ ...record, pay_date: !!record.pay_date }} source={source} />}  />

                        <FunctionField
                            label="Valor" source="value"
                            render={record => `R$${record.value.toLocaleString('pt-br')}`}
                        />;
                        <BooleanField source="is_nfe_issued"  />
                        <DateField source="nfe_date" label="Data NFe" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        <ArrayField source="types" basePath="/job_type" fieldKey="id" >
                            <SingleFieldList>
                                <ColoredChipField source="name" />
                            </SingleFieldList>
                        </ArrayField>

                        <TextField source="status.name" />
                        <FunctionField source="report_url" label="Relatório" sortable={false}
                            render={(record, source) =>
                                <BooleanField record={{ ...record, report_url: !!record.report_url }} source={source}
                                     />} />
                        <EditButton label="" />
                        <CloneButton label="" />
                        <DeleteButton label="" />
                    </Datagrid>
                ) : (
                    <Datagrid rowClick="show">
                        {/* <TextField source="id" /> */}
                        <ReferenceField label="Cliente" source="client_id" reference="clients" ><TextField source="name" /></ReferenceField>
                        <DateField source="start_date" label="Início" sortBy="start_date" sortByOrder="DESC" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        {/* <DateField source="expected_pay_date"  locales="pt-br" /> */}

                        <FunctionField source="pay_date" label="Pago"
                            render={(record, source) =>
                                <BooleanField record={{ ...record, pay_date: !!record.pay_date }} source={source} />}  />

                        <FunctionField
                            label="Valor" source="value"
                            render={record => `R$${record.value.toLocaleString('pt-br')}`}
                        />;
                        <BooleanField source="is_nfe_issued"  />
                        <ArrayField source="types" basePath="/job_type" fieldKey="id" >
                            <SingleFieldList>
                                <ColoredChipField source="name" />
                            </SingleFieldList>
                        </ArrayField>

                        <TextField source="status.name" />
                        <EditButton label="" />
                        <CloneButton label="" />
                        <DeleteButton label="" />
                    </Datagrid>
                )}
        </List>
    )
}
