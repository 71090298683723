import { ClientsList } from "./ClientsList"
import { ClientsCreate } from "./ClientsCreate"
import { ClientsEdit } from "./ClientsEdit"
import { ClientsShow } from "./ClientsShow"


const Clients = {
    list: ClientsList,
    create: ClientsCreate,
    edit: ClientsEdit,
    show: ClientsShow
};

export default Clients