import * as React from "react";
import {
    Create, TextInput, SimpleForm, DateInput, NumberInput, BooleanInput, ReferenceInput, SelectInput, AutocompleteInput,
    ReferenceArrayInput, AutocompleteArrayInput, FormDataConsumer, required, maxLength, number, minValue,
} from 'react-admin';
import { useMediaQuery, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { RichTextInput } from 'ra-input-rich-text';
import { RichTextSmallToolbar } from '../../layout/RichTextSmallToolbar';

let today = new Date();
today.setDate(today.getDate());
var todayStr = today.toISOString().substring(0, 10);

const validateUrlsLength = [maxLength(250)]
const validateMinNumbers = [number(), minValue(0)]

export const JobCreate = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const location = useLocation();
    const client_id = location.state.record ? location.state.record.client_id : null


    const redirectBig = client_id ? `/clients/${client_id}/show/3` : '/jobs';
    const redirectMobile = client_id ? `/clients/${client_id}/show/2` : '/jobs';
    const redirect = isSmall ? redirectMobile : redirectBig;


    return (

        <Create redirect={redirect} >
            {isSmall ? (
                <SimpleForm warnWhenUnsavedChanges variant="standard" >
                    <DateInput source="start_date" validate={required()} defaultValue={todayStr} />
                    <DateInput source="expected_pay_date" validate={required()} />
                    <DateInput source="pay_date" label="Data do pgto" />
                    <NumberInput source="value"
                        validate={validateMinNumbers} placeholder={"R$"} />
                    <ReferenceArrayInput source="type_ids" reference="job_type"
                        filterToQuery={searchText => ({ name: searchText })}
                        // enableGetChoices={({ name }) => name?.length >= 2 || name?.length === 0}
                        perPage={100} >
                        <AutocompleteArrayInput label="Tipos do trabalho" optionText="name" filterToQuery={searchText => ({ name: searchText })} validate={required()}
                            sx={{
                                minWidth: '13rem',
                            }} />
                    </ReferenceArrayInput>
                    <ReferenceInput source="client_id" reference="clients"
                        filterToQuery={searchText => ({ name: searchText })}
                        // enableGetChoices={({ name }) => name?.length >= 2 || name?.length === 0}
                        filter={{ status: true }} >
                        <AutocompleteInput label="Nome do cliente" optionText="name" filterToQuery={searchText => ({ name: searchText })} validate={required()}
                            sx={{
                                minWidth: '13rem',
                            }} />
                    </ReferenceInput>
                    <RichTextSmallToolbar size={'small'} label={false} source="description" fullWidth helperText="Descrição da nota" />
                    <NumberInput source="costs"
                        validate={validateMinNumbers} placeholder={"R$"} />
                    <NumberInput source="estimated_hours" validate={validateMinNumbers} />
                    <BooleanInput label="Nota fiscal emitida" source="is_nfe_issued" />
                    <FormDataConsumer  >
                        {({ formData, ...rest }) => formData.is_nfe_issued &&
                            <TextInput label="URL da NFe" source="nfe_url" {...rest} validate={validateUrlsLength} />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer >
                        {({ formData, ...rest }) => formData.is_nfe_issued &&
                            <DateInput source="nfe_date" {...rest} label="Data emissão NFe" defaultValue={todayStr} />
                        }
                    </FormDataConsumer>
                    <TextInput source="boleto_url" validate={validateUrlsLength} />
                    <TextInput source="pix_url" validate={validateUrlsLength} />
                    <TextInput source="report_url" validate={validateUrlsLength} />
                    <ReferenceInput source="pay_account.id" reference="payment_account">
                        <SelectInput optionText="name" validate={required()}
                            sx={{
                                minWidth: '13rem',
                            }} />
                    </ReferenceInput>
                    <ReferenceInput source="pay_type.id" reference="payment_type">
                        <SelectInput optionText="name" validate={required()}
                            sx={{
                                minWidth: '13rem',
                            }} />
                    </ReferenceInput>
                    <ReferenceInput source="status.id" reference="job_status">
                        <SelectInput optionText="name" validate={required()} sx={{
                            minWidth: '13rem',
                        }} />
                    </ReferenceInput>
                    <Box sx={{ paddingBottom: '4rem' }}></Box>
                </SimpleForm>
            ) : (
                <SimpleForm warnWhenUnsavedChanges variant="standard" >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <DateInput source="start_date" defaultValue={todayStr} validate={required()} />
                        <DateInput source="expected_pay_date" validate={required()} />
                        <DateInput source="pay_date" label="Data do pgto" />
                        <NumberInput source="value"
                            validate={validateMinNumbers} placeholder={"R$"} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <ReferenceInput source="client_id" reference="clients"
                            // enableGetChoices={({ name }) => name?.length >= 2 || name?.length === 0}
                            filter={{ status: true }} >
                            <AutocompleteInput label="Nome do cliente" optionText="name" filterToQuery={searchText => ({ name: searchText })}
                                validate={required()}
                                sx={{
                                    minWidth: '13rem',
                                }} />
                        </ReferenceInput>
                        <ReferenceArrayInput source="type_ids" reference="job_type"
                            // enableGetChoices={({ name }) => name?.length >= 2 || name?.length === 0}
                            perPage={100} >
                            <AutocompleteArrayInput label="Tipos do trabalho" optionText="name" filterToQuery={searchText => ({ name: searchText })}
                                validate={required()}
                                sx={{
                                    minWidth: '13rem',
                                }} />
                        </ReferenceArrayInput>
                    </Box>
                    <Box sx={{
                        width: '100%',
                        wordBreak: "break-word",
                    }} >
                        <RichTextInput label={false} source="description" fullWidth helperText="Descrição da nota" />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <NumberInput source="costs"
                            validate={validateMinNumbers} placeholder={"R$"} />
                        <NumberInput source="estimated_hours" validate={validateMinNumbers} />
                    </Box>
                    <BooleanInput label="Nota fiscal emitida" source="is_nfe_issued" />
                    <FormDataConsumer  >
                        {({ formData, ...rest }) => formData.is_nfe_issued &&
                            <TextInput label="URL da NFe" source="nfe_url" {...rest} validate={validateUrlsLength} />
                        }
                    </FormDataConsumer>
                    <FormDataConsumer >
                        {({ formData, ...rest }) => formData.is_nfe_issued &&
                            <DateInput source="nfe_date" {...rest} label="Data emissão NFe" defaultValue={todayStr} />
                        }
                    </FormDataConsumer>
                    <TextInput source="boleto_url" validate={validateUrlsLength} />
                    <TextInput source="pix_url" validate={validateUrlsLength} />
                    <TextInput source="report_url" validate={validateUrlsLength} />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <ReferenceInput source="pay_account.id" reference="payment_account" >
                            <SelectInput optionText="name" validate={required()}
                                sx={{
                                    minWidth: '13rem',
                                }} />
                        </ReferenceInput>
                        <ReferenceInput source="pay_type.id" reference="payment_type"

                        ><SelectInput optionText="name" validate={required()}
                            sx={{
                                minWidth: '13rem',
                            }} />
                        </ReferenceInput>
                        <ReferenceInput source="status.id" reference="job_status">
                            <SelectInput optionText="name" validate={required()}
                                sx={{
                                    minWidth: '13rem',
                                }} />
                        </ReferenceInput>
                    </Box>
                </SimpleForm>
            )
            }
        </Create>
    )
}