import { defaultTheme } from 'react-admin';

import red from '@mui/material/colors/red';

export const darkTheme = {
    ...defaultTheme,
    palette: {
        mode: 'dark',
        primary: {
            light: '#595959',
            main: '#EFC44F',
            dark: '#2F3A5F',
            contrastText: '#fff',
            error: red,
            contrastThreshold: 3,
            tonalOffset: 0.2,
            darkGray: '#202023',
        },
        secondary: {
            light: '#C5C5C5',
            main: '#EFC44F',
            dark: '#DA1153',
            contrastText: '#000',
            error: red,
            contrastThreshold: 3,
            tonalOffset: 0.2,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1921,
        },
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    sidebar: {
        // width: 350, // The default value is 240
        closedWidth: 50, // The default value is 55
    },
    components: {
        ...defaultTheme.components,
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderLeft: '2px solid #919191', // invisible menu when not active, to avoid scrolling the text when selecting the menu
                    '&.RaMenuItemLink-active': {
                        borderLeft: '2px solid #EFC44F',
                        marginRight: '4px',
                        backgroundColor: 'rgba(239, 196, 79, 0.15)',   
                        '& .RaMenuItemLink-icon': {
                            color: '#EFC44F',
                        },                     
                    },
                    '& .RaMenuItemLink-icon': {
                        color: '#ffffff',
                    },
                },
            },
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#292929',                    
                },
            },
            
        },
        MuiTypography: {            
            styleOverrides: {
                body2: {
                    borderBottom: '1px solid rgba(239, 196, 79, 0.15)', // invisible menu when not active, to avoid scrolling the text when selecting the menu
                },
            },
        },
    },
};


export const lightTheme = {
    ...defaultTheme,
    palette: {
        mode: 'light',
        primary: {
            light: '#ededed',
            main: '#1183da',
            dark: '#7996f7',
            contrastText: '#fff',
            error: red,
            contrastThreshold: 3,
            tonalOffset: 0.2,
        },
        secondary: {
            light: '#ba68c8',
            main: '#EFC44F', // '#9c27b0',
            dark: '#da1153',
            contrastText: '#fff',
            error: red,
            contrastThreshold: 3,
            tonalOffset: 0.2,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1921,
        },
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    sidebar: {
        // width: 240, // The default value is 240
        closedWidth: 50, // The default value is 55
    },
    components: {
        ...defaultTheme.components,
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    // borderLeft: '2px solid #919191', // invisible menu when not active, to avoid scrolling the text when selecting the menu
                    '&.RaMenuItemLink-active': {
                        borderLeft: '2px solid #EFC44F',
                        marginRight: '4px',
                        backgroundColor: 'rgba(239, 196, 79, 0.15)',
                        '& .RaMenuItemLink-icon': {
                            color: '#303030',
                        },               
                    },
                    '& .RaMenuItemLink-icon': {
                        color: '#EFC44F',
                    },
                },
            },
            
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f5f6fa',                    
                },
            },
            
        },
    },    
};
