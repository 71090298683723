import * as React from 'react';
import { createElement } from 'react';
import { useMediaQuery } from '@mui/material';
import DefaultIcon from '@mui/icons-material/ViewList';
// import { lightTheme } from "./CustomTheme"
import { Menu, MenuItemLink, DashboardMenuItem, useResourceDefinitions, useSidebarState, useTranslate, useTheme } from 'react-admin';


let Logo = 'https://diegogaona.com/wp-content/uploads/2020/04/logo-diegogaonacom.svg'

export const MyMenu = (props) => {
    const translate = useTranslate();
    const resources = useResourceDefinitions()
    const [open] = useSidebarState();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [theme, ] = useTheme()

    return (
        <>
            {isSmall ? (
                <Menu {...props}>
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: theme.palette.primary.main,
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "13px"
                        }}>
                        <img
                            src={Logo} style={{
                                width: "95px",
                            }}
                            alt="diegogaona.com" />
                    </div>
                    <DashboardMenuItem />
                    {Object.keys(resources).map(name => (
                        <MenuItemLink
                            key={name}
                            // to={`/${name}`}
                            to={(resources[name].options && resources[name].options.url) || `/${name}`}
                            primaryText={
                                (resources[name].options && resources[name].options.label)
                                || translate(`resources.${name}.nameplural`)
                                || name
                            }
                            leftIcon={
                                resources[name].icon ? createElement(resources[name].icon) : <DefaultIcon />
                            }
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                    ))}
                    {/* add your custom menus here */}
                </Menu>
            ) : (
                <Menu {...props}>
                    <DashboardMenuItem />
                    {Object.keys(resources).map(name => (
                        <MenuItemLink
                            key={name}
                            to={(resources[name].options && resources[name].options.url) || `/${name}`}
                            primaryText={
                                (resources[name].options && resources[name].options.label)
                                || translate(`resources.${name}.nameplural`)
                                || name
                            }
                            leftIcon={
                                resources[name].icon ? createElement(resources[name].icon) : <DefaultIcon />
                            }
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                    ))}
                    {/* add your custom menus here */}
                </Menu>
            )}
        </>
    )
}