import * as React from "react";
import {
    TextInput, Create, SimpleForm, Toolbar,
    SaveButton, SelectArrayInput, required, useNotify, RadioButtonGroupInput, useRefresh
} from 'react-admin';
import { Box } from '@mui/material';
import Send from '@mui/icons-material/Send';
import FixedData from "../../data/FixedData.json"

const CustomToolbar = () => (
    <Toolbar>
        <SaveButton label="CRIAR/ATUALIZAR" variant="contained" icon={<Send />} />
    </Toolbar>
)

export const WordpressSendinCreate = () => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify(`ra.notification.send_email`, { type: 'success' });
        refresh();
    }

    const onFailure = () => {
        notify(`ra.notification.send_email_fail`, { type: 'error' });
        refresh();
    }

    return (

        <Create title="Criar usuário WP e contato Sendinblue"
            mutationOptions={{
                onSuccess: onSuccess,
                onError: onFailure
            }} >
            <SimpleForm variant="standard" toolbar={<CustomToolbar />} >
                <h3>Criação/Atualização de usuário no WordPress e contato no Sendinblue </h3>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    columnGap: '1rem',
                }}>
                    <SelectArrayInput label="Lista de contato para Sendinblue" source="list_ids" optionValue="list_id"
                        choices={FixedData.contact_lists} sx={{ minWidth: '20rem' }} >
                    </SelectArrayInput>
                    <TextInput source="email" label="e-mail" placeholder="Para usuário WP e contato Sendinblue" validate={required()}
                        type="email" parse={v => v.trim()} sx={{ minWidth: '20rem' }} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    columnGap: '1rem',
                }}>
                    <TextInput source="first_name" label="Primeiro nome" validate={[required()]} />
                    <TextInput source="username" label="Nome do usuário" placeholder="Nome do usuário do Wordpress" validate={required()} />
                    <TextInput source="password" label="Senha" placeholder="Senha do Wordpress" validate={required()} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    columnGap: '1rem',
                }}>
                    <TextInput source="company_name" label="Nome da empresa" />
                    <TextInput source="company_site" label="Site da empresa" />
                </Box>
                <TextInput source="proposta_url" label="URL da proposta" fullWidth />
                <RadioButtonGroupInput source="platform" label="Enviar dados para" validate={required()} initialValue="WordPress e Sendinblue"
                    choices={[
                        { id: 'WordPress e Sendinblue', name: 'WordPress e Sendinblue' },
                        { id: 'WordPress', name: 'WordPress' },
                        { id: 'Sendinblue', name: 'Sendinblue' },
                    ]} />
            </SimpleForm>
        </Create>
    )
};

// http://localhost:3000/#/send_email/credit/create

export default WordpressSendinCreate