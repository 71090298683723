import React from 'react';
import { Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';



export function DualAxisChart(props) {
    defaults.color = "#BDBDBD";
    defaults.plugins.tooltip.titleColor = "#EFC44F";
    const { mylabels, myvalues, myvalues2, chartType1, chartType2, data1Name, data2Name, chartTitle, hide,
        axisTitle1, axisTitle2, XaxisTitle, mobile } = props
    const data = {
        labels: mylabels,
        datasets: [
            {
                type: chartType1,
                label: data1Name,
                data: myvalues,
                fill: true,
                borderColor: "#EFC44F",
                backgroundColor: "rgba(239, 196, 79, 0.1)",
                pointBackgroundColor: "#EFC44F",
                pointBorderColor: "#FFFF",
                pointHoverBackgroundColor: "#EFC44F",
                pointHoverBorderColor: "#EFC44F",
                pointStyle: 'circle',
                yAxisID: 'y',
            },
            {
                type: chartType2,
                label: data2Name,
                data: myvalues2,
                // fill: true,
                backgroundColor: 'rgba(218, 17, 83, 0.5)',
                borderColor: 'rgba(218, 17, 83, 1)',
                pointBackgroundColor: "rgb(218, 17, 83)",
                pointBorderColor: "#FFFF",
                pointHoverBackgroundColor: "rgb(218, 17, 83)",
                pointHoverBorderColor: "rgb(218, 17, 83)",
                pointStyle: 'triangle',
                yAxisID: 'y2',
            },
        ],
    };

    const options = {
        // legend: {
        //     display: false,
        //     position: 'top',
        //  },
        responsive: true,
        scales: {
            y: {
                beginAtZero: false,
                display: true,
                title: {
                    display: true,
                    text: axisTitle1,
                    color: '#EFC44F',
                },
            },
            y2: {
                beginAtZero: true,
                display: true,
                position: "right",
                title: {
                    display: true,
                    text: axisTitle2,
                    color: 'rgba(218, 17, 83, 1)',
                },
            },
            xAxes: {
                title: {
                    display: true,
                    text: XaxisTitle,
                    color: '#FFF',
                    // font: {
                    //     size: 15
                    // }
                },
                ticks: {
                    display: false //this will remove only the label
                }
            }
        },
        plugins: {
            title: {
                display: true,
                text: chartTitle,
            },
        },
        tooltip: {
            usePointStyle: true,
        }
    };

    const optionsMobile = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: false,
                display: true,
            },
            y2: {
                beginAtZero: true,
                display: true,
                position: "right",
            },
        },
        plugins: {
            title: {
                display: true,
                text: chartTitle,
            },
        },
        tooltip: {
            usePointStyle: true,
        }
    };


    if (mobile === true && hide === false) {
        return (
            <>
                <Line data={data} options={optionsMobile} />
            </>
        )
    }

    if (hide === false) {
        return (
            <>
                <Line data={data} options={options} />
            </>
        )
    }
    else {
        return null
    }
};

DualAxisChart.defaultProps = {
    chartType1: "line",
    chartType2: "line",
    mylabels: [0, 0],
    myvalues: [0, 0],
    myvalues2: [0, 0],
    data1Name: "Data 1",
    data2Name: "Data 2",
    tooltipXpre: "",
    tooltipYpre: "",
    chartTitle: "",
    hide: false,
    mobile: false,
}