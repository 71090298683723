import {
    number,
    email,
} from 'react-admin';


const validateClientCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'ra.validation.nameRequired';
    }
    if (!values.email) {
        errors.email = 'ra.validation.required';
    } else if (!email(values.email)) {
        // console.log(email(values.email))
        errors.email = {
            message: 'ra.validation.email',
        };
    }
    else if (values.email.length > 60) {
        errors.email = {
            message: 'ra.validation.maxLength',
            args: { max: 60 }
            
        };
    }
    if (!number(values.discount_period)) {
        errors.discount_period = 'ra.validation.number';
    }
    if (!number(values.discount_perc)) {
        errors.discount_perc = 'ra.validation.number';
    }
    if (!values.industry) {
        errors.industry = 'ra.validation.industryRequired';
    }
    return errors
};

export default validateClientCreation