import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';

// const apiUrl = "http://localhost:8000"
// export const apiUrl = 'https://fast.diegogaona.com'
export const apiUrl = (process.env.REACT_APP_BACK_END_URL)

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('auth');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};


const dataProvider = jsonServerProvider(apiUrl, httpClient);

const myDataProvider = {
    ...dataProvider,
    update: async (resource, params) => {
        const { id, created_at, updated_at, owner, owner_id, exclude, ...data } = params.data;

        // don't forget to return the promise!
        try {
            const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(data),
            });
            return ({ data: json });
        } catch (err) {
            return console.log(err);
        }
    },

    create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/`, {
        method: 'POST',
        body: JSON.stringify(params.data),
    }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
    })),

    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        // Modified to use / in the end of the url
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;

        const { headers, json } = await httpClient(url);
        if (!headers.has('x-total-count')) {
            throw new Error(
                'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
            );
        }
        return {
            data: json,
            total: parseInt(
                headers.get('x-total-count').split('/').pop(),
                10
            ),
        };
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: async (resource, params) => {
        const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;
        const { json } = await httpClient(url);
        return ({ data: json });
    },

    getManyReference: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}/?${stringify(query)}`;

        const { headers, json } = await httpClient(url);
        if (!headers.has('x-total-count')) {
            throw new Error(
                'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
            );
        }
        return {
            data: json,
            total: parseInt(
                headers.get('x-total-count').split('/').pop(),
                10
            ),
        };
    },

    // To get the aggregated sums for jobs and clients
    getSum: async (resource, params) => {    
        // const query = params.ids === undefined ? null : params.ids;   
        const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}${stringify(query)}`;
        const { json } = await httpClient(url);
        return ( json );
    },

    sendEmail: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/`, {
        method: 'POST',
        body: JSON.stringify(params.data),
    }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
    })),
    
};

export default myDataProvider;