import * as React from "react";
import {
    TextField, Show, TabbedShowLayout, Tab, CloneButton, useGetRecordId, Labeled
} from 'react-admin';

import { useMediaQuery, Box } from '@mui/material';
import { ColoredChipField } from "../ColoredChips.tsx"
import { ShowActions } from "../Actions"

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';

import CardWithIcon from "../../layout/CardWithIcon"
import { DualAxisChart } from "../../charts/ChartWithTwoDataSets"
import { ShowSumId, ShowGroupSumId } from "../../data/ValueSumWithId"
import { yearBeforeStr, todayStr, yearBeginStr } from "../../data/Dates"

export const JobTypeModelShow = () => {
    const sumType = "jobs/"
    const itemType = "job_type"
    const recordId = useGetRecordId();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const chartData12Months = ShowGroupSumId(sumType, itemType, `by_month=true&job_start_date_start=${yearBeforeStr}`, "", recordId)
    const chartData12Months2 = ShowGroupSumId(sumType, itemType, `by_month=true&job_start_date_start=${yearBeforeStr}&count=true`, "", recordId)

    return (
        <Show actions={<ShowActions />} >
            {isSmall ? (
                <TabbedShowLayout >
                    <Tab label="Principal">
                        <TextField source="id" />
                        <ColoredChipField label="Nome" source="name" />
                        <TextField label="Cor" source="color" />
                        <TextField label="Descrição" source="description" />
                        <CloneButton variant="outlined" />
                    </Tab>

                    <Tab label="Dashboard">
                        <Box  >
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries="count=true" />}
                                subtitle={"Trabalhos"}
                            />
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`count=true&job_start_date_start=${yearBeginStr}`} />}
                                subtitle={"Trabalhos esse ano"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType} />}
                                subtitle={"Total recebido"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceWalletOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`} />}
                                subtitle={"Com NFe esse ano"}
                            />
                            <CardWithIcon
                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Últimos 12 meses"}
                            />
                            <CardWithIcon
                                icon={TodayOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`job_start_date_start=${yearBeginStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Esse ano"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={"avg=true"} />}
                                subtitle={"Média por trabalho"}
                            />
                            <CardWithIcon
                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`avg=true&job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Média 12 meses"}
                            />
                        </Box>
                        <Box >
                            <DualAxisChart
                                // chartTitle="Faturamento R$ vs número de trabalhos"
                                mylabels={chartData12Months.chartlabels}
                                myvalues={chartData12Months.chartvalues}
                                data1Name={"Faturamento R$"}
                                data2Name={"Número de trabalhos"}
                                myvalues2={chartData12Months2.chartvalues}
                                mobile={true} />
                        </Box>
                    </Tab>
                </TabbedShowLayout>
            ) : (
                <TabbedShowLayout >
                    <Tab label="Principal">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            columnGap: '2rem',
                        }}>
                            <Labeled>
                                <TextField source="id" />
                            </Labeled>
                            <Labeled>
                                <ColoredChipField label="Nome" source="name" />
                            </Labeled>
                            <Labeled>
                                <TextField label="Cor" source="color" />
                            </Labeled>
                        </Box>
                        <TextField label="Descrição" source="description" />
                    </Tab>

                    <Tab label="Dashboard">
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            columnGap: "0.8rem",
                        }} >
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries="count=true" />}
                                subtitle={"Trabalhos"}
                            />
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`count=true&job_start_date_start=${yearBeginStr}`} />}
                                subtitle={"Trabalhos esse ano"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType} />}
                                subtitle={"Total recebido"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceWalletOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`} />}
                                subtitle={"Com NFe esse ano"}
                            />
                            <CardWithIcon
                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Últimos 12 meses"}
                            />
                            <CardWithIcon
                                icon={TodayOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`job_start_date_start=${yearBeginStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Esse ano"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={"avg=true"} />}
                                subtitle={"Média por trabalho"}
                            />
                            <CardWithIcon
                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`avg=true&job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Média 12 meses"}
                            />
                        </Box>
                        <Box sx={{
                            minWidth: '50%',
                            maxWidth: '70%',
                            margin: "auto",
                            textAlign: "center",
                        }}>
                            <DualAxisChart
                                // chartTitle="Faturamento R$ vs número de trabalhos"

                                mylabels={chartData12Months.chartlabels}
                                myvalues={chartData12Months.chartvalues}
                                data1Name={"Faturamento R$"}
                                data2Name={"Número de trabalhos"}
                                myvalues2={chartData12Months2.chartvalues}
                                tooltipXpre={"R$ "}
                                tooltipYpre={"Trabalhos"}
                                axisTitle1={"R$"}
                                axisTitle2={"TRABALHOS"}
                                XaxisTitle={"DATA"} />
                        </Box>
                    </Tab>
                </TabbedShowLayout>
            )
            }
        </Show>
    )
};