let today = new Date();
export const todayStr = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`

export const yearBeginStr = `${today.getFullYear()}-01-01`

let yearBefore = new Date();
yearBefore.setMonth(today.getMonth() - 12);
export const  yearBeforeStr = `${yearBefore.getFullYear()}-${yearBefore.getMonth()+1}-01`
// export const  exactOneYearBeforeStr = `${yearBefore.getFullYear()}-${yearBefore.getMonth()+1}-${yearBefore.getDate()=1}`


let beginMonthBeforeDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
export const beginMonthBefore = `${beginMonthBeforeDate.getFullYear()}-${beginMonthBeforeDate.getMonth() +1}-01`

let endMonthBeforeDate = new Date();
endMonthBeforeDate.setDate(0);
export const endMonthBefore = `${endMonthBeforeDate.getFullYear()}-${endMonthBeforeDate.getMonth() +1}-${endMonthBeforeDate.getDate()}`

let beginCurrentMonthDate = new Date(today.getFullYear(), today.getMonth(), 1);
export const beginCurrentMonth = `${beginCurrentMonthDate.getFullYear()}-${beginCurrentMonthDate.getMonth() +1}-01`

let endCurrentMonthDate = new Date(today.getFullYear(), today.getMonth()+1, 0);
export const endCurrentMonth = `${endCurrentMonthDate.getFullYear()}-${endCurrentMonthDate.getMonth() +1}-${endCurrentMonthDate.getDate()}`