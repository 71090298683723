import * as React from "react";
import {
    TextField, Show, TabbedShowLayout, Tab, useGetRecordId, useResourceContext, Labeled
} from 'react-admin';

import { useMediaQuery, Box } from '@mui/material';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';

import { ShowActions } from "../Actions"
import CardWithIcon from "../../layout/CardWithIcon";
import { DualAxisChart } from "../../charts/ChartWithTwoDataSets"
import { ShowSumId, ShowGroupSumId } from "../../data/ValueSumWithId"
import { yearBeforeStr, todayStr, yearBeginStr } from "../../data/Dates"

// Conta de pagamento, tipo de pagamento e status dos trabalhos, tipos de trabalho são do JobsSum
// Tipos de cliente, objetivos dos clientes, são do clients sum

export const BaseModelShow = () => {
    const recordId = useGetRecordId();
    const resourceName = useResourceContext();

    var sumType
    var itemType
    var hideChart
    var chartData12Months
    var chartData12Months2
    switch (resourceName) {
        case 'payment_type':
        case 'paymet_account':
        case 'job_status':
            sumType = "jobs/"
            itemType = resourceName
            chartData12Months = ShowGroupSumId(sumType, resourceName, `by_month=true&job_start_date_start=${yearBeforeStr}`, "", recordId)
            chartData12Months2 = ShowGroupSumId(sumType, resourceName, `by_month=true&job_start_date_start=${yearBeforeStr}&count=true`, "", recordId)
            break;
        case 'client_objective':
            sumType = "clients/"
            itemType = "objective_id"
            hideChart = true
            chartData12Months = 0
            chartData12Months2 = 0
            break;
        case 'client_type':
            sumType = "clients/"
            itemType = "type_of_client_id"
            hideChart = true
            chartData12Months = 0
            chartData12Months2 = 0
            break;
        default:
            sumType = "jobs/";
            itemType = resourceName
            chartData12Months = ShowGroupSumId(sumType, resourceName, `by_month=true&job_start_date_start=${yearBeforeStr}`, "", recordId)
            chartData12Months2 = ShowGroupSumId(sumType, resourceName, `by_month=true&job_start_date_start=${yearBeforeStr}&count=true`, "", recordId)
            break;
    }

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Show actions={<ShowActions />}>
            {isSmall ? (
                <TabbedShowLayout >
                    <Tab label="Principal">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            columnGap: '2rem',
                        }}>
                            <Labeled>
                                <TextField source="id" />
                            </Labeled>
                            <Labeled>
                                <TextField label="Nome" source="name" />
                            </Labeled>
                        </Box>
                        <TextField label="Descrição" source="description" />
                    </Tab>

                    <Tab label="Dashboard" >
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            columnGap: "0.8rem",
                        }}>
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries="count=true" />}
                                subtitle={"Trabalhos"}
                            />
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`count=true&job_start_date_start=${yearBeginStr}`} />}
                                subtitle={"Trabalhos esse ano"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType} />}
                                subtitle={"Total recebido"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceWalletOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`} />}
                                subtitle={"Com NFe esse ano"}
                            />
                            <CardWithIcon
                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Últimos 12 meses"}
                            />
                            <CardWithIcon
                                icon={TodayOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`job_start_date_start=${yearBeginStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Esse ano"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={"avg=true"} />}
                                subtitle={"Média por trabalho"}
                            />
                            <CardWithIcon

                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`avg=true&job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Média 12 meses"}
                            />
                        </Box>
                        <Box sx={{
                                minHeight: '15rem',
                            }} >
                            <DualAxisChart
                                // chartTitle="Faturamento R$ vs número de trabalhos"
                                mylabels={chartData12Months.chartlabels}
                                myvalues={chartData12Months.chartvalues}
                                data1Name={"Faturamento R$"}
                                data2Name={"Número de trabalhos"}
                                myvalues2={chartData12Months2.chartvalues}
                                mobile={true}
                                hide={hideChart} />
                        </Box>
                    </Tab>
                </TabbedShowLayout>
            ) : (
                <TabbedShowLayout >
                    <Tab label="Principal">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap',
                            columnGap: '2rem',
                        }}>
                            <Labeled>
                                <TextField source="id" />
                            </Labeled>
                            <Labeled>
                                <TextField label="Nome" source="name" />
                            </Labeled>
                        </Box>
                        <TextField label="Descrição" source="description" />
                    </Tab>

                    <Tab label="Dashboard">
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            columnGap: "0.8rem",
                        }} >
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries="count=true" />}
                                subtitle={"Trabalhos"}
                            />
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`count=true&job_start_date_start=${yearBeginStr}`} />}
                                subtitle={"Trabalhos esse ano"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType} />}
                                subtitle={"Total recebido"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceWalletOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`} />}
                                subtitle={"Com NFe esse ano"}
                            />
                            <CardWithIcon
                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Últimos 12 meses"}
                            />
                            <CardWithIcon
                                icon={TodayOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`job_start_date_start=${yearBeginStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Esse ano"}
                            />
                            <CardWithIcon
                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={"avg=true"} />}
                                subtitle={"Média por trabalho"}
                            />
                            <CardWithIcon
                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ShowSumId
                                    sumtype={sumType}
                                    itemtype={itemType}
                                    queries={`avg=true&job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Média 12 meses"}
                            />
                        </Box>
                        <Box sx={{
                            minWidth: '50%',
                            maxWidth: '70%',
                            margin: "auto",
                            textAlign: "center",
                        }}>
                            <DualAxisChart
                                // chartTitle="Faturamento R$ vs número de trabalhos"

                                mylabels={chartData12Months.chartlabels}
                                myvalues={chartData12Months.chartvalues}
                                data1Name={"Faturamento R$"}
                                data2Name={"Número de trabalhos"}
                                myvalues2={chartData12Months2.chartvalues}
                                tooltipXpre={"R$ "}
                                tooltipYpre={"Trabalhos"}
                                axisTitle1={"R$"}
                                axisTitle2={"TRABALHOS"}
                                XaxisTitle={"DATA"}
                                hide={hideChart} />
                        </Box>
                    </Tab>
                </TabbedShowLayout>
            )
            }
        </Show>
    )
};