import { JobList } from "./JobsList"
import { JobCreate } from "./JobsCreate"
import { JobEdit } from "./JobsEdit"
import { JobShow } from "./JobsShow"

const Jobs = {
    list: JobList,
    create: JobCreate,
    edit: JobEdit,
    show: JobShow
};

export default Jobs