import * as React from 'react';
import { createElement } from 'react';
import { Card, Box, Typography, Divider } from '@mui/material';
import { useTheme } from "react-admin";

const CardWithIcon = (props) => {
    const { icon, titlePrefix, title, subtitle, children } = props;
    const [theme, ] = useTheme()
    return (
        <Card sx={{
            minHeight: "5rem",
            minWidth: "9rem",
            maxWidth: "15rem",
            marginTop: "1rem",
            marginBottom: "1rem",
            display: 'flex',
            flexDirection: 'column',
            padding: "0.5rem",
            // flex: '2',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.darkGray,
        }}>
            <div sx={{
                overflow: 'inherit',
                padding: 10,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .icon': {
                    color: theme.palette.mode === 'dark' ? 'inherit' : '#dc2440',
                }
            }}>
                <Box sx={{
                    textAlign: "center",
                }}>
                    {createElement(icon, { fontSize: 'large' })}
                    <Typography
                        sx={{ variant: "h1", }}
                        color="primary"
                        variant="h5"
                    >
                        {titlePrefix}{title}
                    </Typography>
                    <Typography variant="body2"
                        // component="h6"
                        color="textSecondary">
                        {subtitle || ' '}
                    </Typography>
                </Box>
            </div>
            {children && <Divider />}
            {children}
        </Card >
    );
};

export default CardWithIcon;