// const apiUrl = 'http://localhost:8000'
// const apiUrl = 'https://fast.diegogaona.com'
const apiUrl = (process.env.REACT_APP_BACK_END_URL)

const authProvider = {
    login: ({username, password}) => {

        const oAuthParams = {
            username,
            password
        }
        const body = Object.keys(oAuthParams).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(oAuthParams[key]);
          }).join('&');

        const request = new Request(`${apiUrl}/auth/jwt/login`, {
            method: 'POST',
            body: body,
            headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'}),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(( {access_token} ) => {
                localStorage.setItem('auth', access_token);
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject({redirectTo: '/login'});
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('auth')
    ? Promise.resolve()
    : Promise.reject({ message: 'Login necessário', redirectTo: 'login' }),

    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },

    getPermissions: () => {
        return Promise.resolve();
    },
    // getIdentity: () => {
    //     return Promise.resolve(); // TODO: GET THE USER EMAIL
    // }
};

export default authProvider;