import EmailIcon from '@mui/icons-material/Email';
import { useNotify, useRecordContext, useDataProvider } from 'react-admin';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';
import { useMutation } from 'react-query';

export const SendFinanceEmailButton = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const notify = useNotify();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { mutate, isLoading } = useMutation(
        ['send_email/finance'],
            
            () => dataProvider.sendEmail(`send_email/finance`, {
                data: {
                    "client_id": record.client_id,
                    "nfe_url": record.nfe_url,
                    "boleto_url": record.boleto_url,
                    "pix_url": record.pix_url,
                    "job_status": record.status.name,
                    "pay_account": record.pay_account.name,
                }                
            }),
            { onSuccess: () => notify('ra.notification.send_email', { type: 'success' }) },
            {
                onFailure: (error) => notify(`Erro no envio: ${error.message}`, { type: 'error' }),
            }
    );


    return (
        <>
            {isSmall ? (
                <Button
                    label="Enviar cobrança"
                    children="Cobrança"
                    onClick={() => mutate()}
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    icon={<EmailIcon />}
                />
            ) : (
                <Button
                    label="Enviar cobrança"
                    children="Enviar cobrança"
                    onClick={() => mutate()}
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    icon={<EmailIcon />}
                />
            )}
        </>
    )
}