import * as React from "react";
import {
    TextInput, Create, SimpleForm, Toolbar,
    SaveButton, SelectInput, required, useNotify, useRefresh
} from 'react-admin';
import Send from '@mui/icons-material/Send';
import FixedData from "../../data/FixedData.json"

const CustomToolbar = () => (
    <Toolbar>
        <SaveButton label="ENVIAR" variant="contained" icon={<Send />} />
    </Toolbar>
)

export const CreditEmailCreate = () => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify(`ra.notification.send_email`, { type: 'success' });
        refresh();
    }

    const onFailure = () => {
        notify(`ra.notification.send_email_fail`, { type: 'error' });
        refresh();
    }

    return (

        <Create title="Enviar e-mail com template"
            mutationOptions={{
                onSuccess: onSuccess,
                onError: onFailure
            }} >
            <SimpleForm variant="standard" toolbar={<CustomToolbar />} >
                <h2>Envio de emails diversos</h2>
                <p>Necessário criar usuário no Sendinblue (e para proposta no WordPress) antes.</p>
                <SelectInput source="template_id" label="Tipo de e-mail" optionValue="template_id" validate={required()}
                    choices={FixedData.other_email_templates} sx={{ minWidth: '15rem' }} />
                <TextInput source="email" label="e-mails de destino (separar por ;)" validate={required()} fullWidth />
                <TextInput source="url" label="URL" fullWidth />
            </SimpleForm>
        </Create>
    )
};

// http://localhost:3000/#/send_email/credit/create

export default CreditEmailCreate