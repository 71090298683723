import * as React from "react";
import {
    Datagrid, TextField, TextInput, NumberInput, BooleanInput, Edit, EditButton, FormTab, TabbedForm, CloneButton, BooleanField, DateField, ArrayField, ReferenceManyField, FunctionField, SingleFieldList, ReferenceInput, SelectInput, ShowButton, required, email, number, minValue, maxLength,
    RichTextField, useRecordContext
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { RichTextSmallToolbar } from '../../layout/RichTextSmallToolbar';
import { useMediaQuery } from '@mui/material';
import { NoteRelatedCreateButton } from './NoteRelatedCreateButton';
import NoteQuickPreviewButton from './NoteQuickPreviewButton';
import { JobRelatedCreateButton } from './JobRelatedCreateButton';
import { ColoredChipField } from "../ColoredChips.tsx";
import EditCreateToolbar from "../EditCreateToolbar";
import { EditActions } from "../Actions"

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

import validateClientCreation from "./ClientValidation"
import { Box } from '@mui/material';

const validateReqEmail = [required(), email()]
const validateMinNumbers = [number(), minValue(0)]
const validateUrlsLength = [maxLength(120)]

export const ClientsEdit = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const ClientName = () => {
        const record = useRecordContext();
        return <span>Cliente: {record ? `${record.name}` : ''}</span>
    }

    return (
        <Edit actions={<EditActions />} title={<ClientName />} {...props}>
            {isSmall ? (
                <TabbedForm warnWhenUnsavedChanges variant="standard" toolbar={<EditCreateToolbar />} validate={validateClientCreation} >
                    <FormTab label="" icon={<PersonOutlineOutlinedIcon />} >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            columnGap: '1rem',
                        }}>
                            {/* <Box sx={{ maxWidth: '5rem' }} >
                                <TextInput label="ID" source="id" disabled />
                            </Box> */}
                            <Box>
                                <TextInput label="Nome" source="name"
                                    fullWidth />
                            </Box>
                            <Box>
                                <TextInput label="CNPJ" source="cnpj" />
                            </Box>
                            <Box>
                                <TextInput label="e-mail" source="email" placeholder="e-mail principal de contato" validate={validateReqEmail} />
                            </Box>
                            <Box>
                                <TextInput source="emails_finance" placeholder="lista de e-mails separada por ;" />
                            </Box>
                            <Box>
                                <TextInput source="emails_reports" placeholder="lista de e-mails separada por ;"
                                />
                            </Box>
                            <Box>
                                <BooleanInput label="Status" source="status" defaultValue={true}
                                />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            columnGap: '1rem',
                        }}>
                            <Box>
                                <TextInput label="Ramo/Setor" source="industry" />
                            </Box>
                            <Box>
                                <NumberInput label="Período de desconto" source="discount_period" defaultValue="3"
                                    validate={validateMinNumbers} />
                            </Box>
                            <Box>
                                <NumberInput label="% de desconto" source="discount_perc" defaultValue="25"
                                    validate={validateMinNumbers} />
                            </Box>

                            <Box>
                                <ReferenceInput source="client_type.id" reference="client_type"
                                >
                                    <SelectInput label="Tipo de cliente" optionText="name" validate={[required()]} /></ReferenceInput>
                            </Box>
                            <Box>
                                <ReferenceInput source="objective.id" reference="client_objective"
                                >
                                    <SelectInput label="Objetivo principal" optionText="name" validate={[required()]} /></ReferenceInput>
                            </Box>
                        </Box>
                        <Box>
                            <TextInput label="URL do Hubspot" source="hubspot_url" validate={validateUrlsLength} />
                        </Box>
                        <Box>
                            <TextInput label="URL da proposta" source="proposal_url" validate={validateUrlsLength} />
                        </Box>
                        <RichTextSmallToolbar size={'small'} label={false} source="description" fullWidth
                            helperText="Descrição principal do cliente" />
                        <Box mb='5rem'></Box>
                    </FormTab>

                    <FormTab label="" icon={<ChatOutlinedIcon />} >
                        <ReferenceManyField label={false} reference="client_notes" target="client_id" sort={{ field: "updated_at", order: "DESC" }} >
                            <Datagrid rowClick="show" >
                                <DateField label="Criado" source="created_at" locales="pt-br" />
                                <RichTextField stripTags source='note' label="Comentário" sx={{
                                    display: "block",
                                    maxWidth: "15ch",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }} />
                            </Datagrid></ReferenceManyField>
                        < NoteRelatedCreateButton />
                    </FormTab>

                    <FormTab label="" icon={<AssignmentTurnedInOutlinedIcon />}>
                        <ReferenceManyField label={false} reference="jobs" target="client_id" sort={{ field: "start_date", order: "DESC" }} >
                            <Datagrid rowClick="show">
                                {/* <TextField source="id" /> */}
                                <DateField source="start_date" label="Início" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                {/* <DateField source="expected_pay_date" label="Pagamento esperado" /> */}

                                <FunctionField source="pay_date" label="Pago" render={(record, source) =>
                                    <BooleanField record={{ ...record, pay_date: !!record.pay_date }} source={source} />} />

                                <FunctionField
                                    label="Valor" source="value"
                                    render={(record) => `R$${record.value}`}
                                />;
                            </Datagrid></ReferenceManyField>
                        <JobRelatedCreateButton />
                    </FormTab>

                </TabbedForm>
            ) : (
                <TabbedForm warnWhenUnsavedChanges variant="standard" toolbar={<EditCreateToolbar />} validate={validateClientCreation} >
                    <FormTab label="Principal">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            columnGap: '1rem',
                        }}>
                            <Box sx={{ maxWidth: '5rem' }} >
                                <TextInput label="ID" source="id" disabled />
                            </Box>
                            <Box>
                                <TextInput label="Nome" source="name"
                                    fullWidth />
                            </Box>
                            <Box>
                                <TextInput label="CNPJ" source="cnpj" />
                            </Box>
                            <Box>
                                <TextInput label="e-mail" source="email" placeholder="e-mail principal de contato" validate={validateReqEmail} />
                            </Box>
                            <Box>
                                <TextInput source="emails_finance" placeholder="lista de e-mails separada por ;" />
                            </Box>
                            <Box>
                                <TextInput source="emails_reports" placeholder="lista de e-mails separada por ;"
                                />
                            </Box>
                            <Box>
                                <BooleanInput label="Status" source="status" defaultValue={true}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ wordBreak: "break-word", width: "100%", }}>
                            <RichTextInput label={false} source="description" fullWidth
                                helperText="Descrição principal do cliente" />
                        </Box>
                    </FormTab>

                    <FormTab label="Outros">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            columnGap: '1rem',
                        }}>
                            <Box>
                                <TextInput label="Ramo/Setor" source="industry" />
                            </Box>
                            <Box>
                                <NumberInput label="Período de desconto" source="discount_period" defaultValue="3"
                                    validate={validateMinNumbers} />
                            </Box>
                            <Box>
                                <NumberInput label="% de desconto" source="discount_perc" defaultValue="25"
                                    validate={validateMinNumbers} />
                            </Box>

                            <Box>
                                <ReferenceInput source="client_type.id" reference="client_type"
                                >
                                    <SelectInput label="Tipo de cliente" optionText="name" validate={[required()]} /></ReferenceInput>
                            </Box>
                            <Box>
                                <ReferenceInput source="objective.id" reference="client_objective"
                                >
                                    <SelectInput label="Objetivo principal" optionText="name" validate={[required()]} /></ReferenceInput>
                            </Box>
                        </Box>
                        <Box>
                            <TextInput label="URL do Hubspot" source="hubspot_url" validate={validateUrlsLength} />
                        </Box>
                        <Box>
                            <TextInput label="URL da proposta" source="proposal_url" validate={validateUrlsLength} />
                        </Box>
                    </FormTab>

                    <FormTab label="Comentários">
                        <ReferenceManyField label="Notas" reference="client_notes" target="client_id" sort={{ field: "updated_at", order: "DESC" }} >
                            <Datagrid>
                                <DateField label="Criado em" source="created_at" locales="pt-br" />
                                <DateField label="Atualizado em" source="updated_at" locales="pt-br" />
                                <RichTextField stripTags source='note' label="Comentário" sx={{
                                    display: "block",
                                    maxWidth: "52ch",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }} />
                                <NoteQuickPreviewButton />
                                <EditButton label="" />
                                <CloneButton label="" />
                            </Datagrid></ReferenceManyField>
                        < NoteRelatedCreateButton />
                    </FormTab>

                    <FormTab label="Trabalhos">
                        <ReferenceManyField label="Trabalhos" reference="jobs" target="client_id" sort={{ field: "start_date", order: "DESC" }} >
                            <Datagrid rowClick="show">
                                {/* <TextField source="id" /> */}
                                <DateField source="start_date" label="Início" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                <DateField source="expected_pay_date" label="Pagamento esperado" locales="pt-br" options={{ timeZone: 'UTC' }} />

                                <FunctionField source="pay_date" label="Pago" render={(record, source) =>
                                    <BooleanField record={{ ...record, pay_date: !!record.pay_date }} source={source} />} />

                                <FunctionField
                                    label="Valor" source="value"
                                    render={record => `R$${record.value.toLocaleString('pt-br')}`}
                                />;
                                <BooleanField source="is_nfe_issued" label="NFe Emitida" />
                                <ArrayField source="types" label="Tipo" ><SingleFieldList>
                                    <ColoredChipField source="name" /></SingleFieldList></ArrayField>
                                <TextField source="status.name" label="Status" />
                                <NoteQuickPreviewButton />
                                <ShowButton label="" />
                                <EditButton label="" />
                                <CloneButton label="" />
                            </Datagrid></ReferenceManyField>
                        <JobRelatedCreateButton />
                    </FormTab>

                </TabbedForm>
            )
            }
        </Edit>
    )
};