import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useGetRecordId } from 'react-admin';
import { Box } from '@mui/material';

export const JobRelatedCreateButton = () => {
    const recordId = useGetRecordId();
    return (
        <Box sx={{
            maxWidth: '15rem',
            marginTop: '1rem',
        }}>
        <Button
            variant="contained"
            color="primary"
            startIcon={<AssignmentIcon />}
            component={Link}
            state={{ record: { client_id: recordId } }}
            to={{
                pathname: '/jobs/create',
            }}
        >
            Criar novo trabalho
        </Button>
        </Box>
    )
}; 