import * as React from "react";
import {
    List, Datagrid, TextField, EditButton, TextInput, CloneButton, DeleteButton, SimpleList
} from 'react-admin';
import { useMediaQuery } from '@mui/material';

import { ListActions } from "../Actions"

const postFilters = [
    <TextInput label="Busca por nome" source="name" alwaysOn variant="standard" />,
    <TextInput label="Descrição" source="description" variant="standard" />,
];

export const BaseModelList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List actions={<ListActions />} filters={postFilters} {...props} empty={false} >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText="%{name}"
                    secondaryText={<TextField sx={{
                        display: "block",
                        maxWidth: "35ch",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                        label="Descrição" source="description" />}
                // tertiaryText={record => (record.id)}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" label="Nome" />
                    <TextField sx={{
                        display: "block",
                        maxWidth: "75ch",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                        label="Descrição" source="description" />
                    <EditButton label="" />
                    <CloneButton label="" />
                    <DeleteButton label="" />
                </Datagrid>
            )}
        </List>
    );
}