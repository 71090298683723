import React, { useState, useCallback } from 'react';
import {
    Datagrid, TextField, EmailField, EditButton, CloneButton, Show, BooleanField, NumberField, DateField, ArrayField, ReferenceManyField, FunctionField, SingleFieldList, TabbedShowLayout, Tab, UrlField, ShowButton, useRedirect, useGetOne, Labeled, RichTextField,
    useRecordContext,
} from 'react-admin';
import { useMediaQuery, Box } from '@mui/material';

import { NoteRelatedCreateButton } from './NoteRelatedCreateButton';
import NoteQuickPreviewButton from './NoteQuickPreviewButton';
import JobQuickPreviewButton from './JobQuickPreviewButton';
import { JobRelatedCreateButton } from './JobRelatedCreateButton';
import CreditEmailQuickCreateButton from './CreditEmailDialog';
import { ColoredChipField } from "../ColoredChips.tsx";
import { ShowActions } from "../Actions"

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';

import CardWithIcon from "../../layout/CardWithIcon";
import { SimpleChart } from "../../charts/SimpleChart"
import { ClientShowSum, ClientShowGroupSum } from "./ClientShowSum";
import { yearBeforeStr, todayStr, yearBeginStr } from "../../data/Dates"
import TextFieldEllipsisMobile from "../../layout/TextFieldEllipsisMobile";

import { useParams } from 'react-router-dom';


export const ClientsShow = props => {
    const { id } = useParams();  // The record id
    const redirect = useRedirect();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isReallyBig = useMediaQuery('(min-width:1921px)')
    const [version, setVersion] = useState(0);
    const handleChange = useCallback(() => setVersion(version + 1), [version]);

    const { data } = useGetOne(
        'clients',
        { id },
        { onError: () => redirect('/clients') }
    );

    const chartData12Months = ClientShowGroupSum(`by_month=true&job_start_date_start=${yearBeforeStr}`, "", id)
    const ClientName = () => {
        const record = useRecordContext();
        return <span>Cliente: {record ? `${record.name}` : ''}</span>
    }

    return (
        <Show actions={<ShowActions />} title={<ClientName />} {...props}>
            {isSmall ? (
                <TabbedShowLayout >
                    <Tab label="" icon={<PersonOutlineOutlinedIcon />} >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            columnGap: '2rem',
                        }}>
                            <Box>
                                <Labeled><TextField source="id" /></Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <TextField source="name" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <TextField source="cnpj" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <EmailField source="email" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <BooleanField source="status" />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            columnGap: '2rem',
                        }}>
                            <Box>
                                <Labeled>
                                    <TextFieldEllipsisMobile source="emails_finance" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <TextFieldEllipsisMobile source="emails_reports" />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            marginTop: '1rem',
                            columnGap: '2rem',
                        }}>
                            <Box>
                                <Labeled>
                                    <TextField source="industry" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <NumberField source="discount_period" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <NumberField source="discount_perc" />
                                </Labeled>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            marginTop: '1rem',
                            columnGap: '2rem',
                        }}>
                            <Box>
                                <Labeled>
                                    <TextField source="client_type.name" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <TextField source="objective.name" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <DateField source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled>
                                    <DateField source="updated_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'space-around',
                            flexWrap: 'wrap',
                            marginTop: '1rem',
                            columnGap: '2rem',
                            rowGap: '1rem',
                        }}>
                            <Labeled>
                                <TextFieldEllipsisMobile source="hubspot_url" emptyText="Não preenchido" />
                            </Labeled>
                            <Labeled>
                                <TextFieldEllipsisMobile source="proposal_url" emptyText="Não preenchido" />
                            </Labeled>
                            <Box>
                                <Labeled>
                                    <RichTextField source="description" sx={{ wordWrap: "break-all", whiteSpace: "pre-wrap" }} />
                                </Labeled>
                            </Box>
                            <Box mt='5rem'>
                                {/* // TODO: NOT WORK IN MOBILE */}
                                <CreditEmailQuickCreateButton onChange={handleChange} />
                            </Box>
                        </Box>

                    </Tab>
                    <Tab label="" icon={<ChatOutlinedIcon />} >
                        <ReferenceManyField reference="client_notes" target="client_id" label={false} sort={{ field: "created_at", order: "DESC" }} >
                            <Datagrid rowClick="show" >
                                <DateField label="Criado" source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                <RichTextField stripTags source='note' label="Comentário" sx={{
                                    display: "block",
                                    maxWidth: "20ch",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }} />
                            </Datagrid></ReferenceManyField>
                        < NoteRelatedCreateButton record={data} />
                    </Tab>

                    <Tab label="" icon={<AssignmentTurnedInOutlinedIcon />} >
                        <ReferenceManyField label="Trabalhos" reference="jobs" target="client_id" sort={{ field: "start_date", order: "DESC" }} >
                            <Datagrid rowClick="show" >
                                <DateField source="start_date" label="Início" locales="pt-br" options={{ timeZone: 'UTC' }} />

                                <FunctionField source="pay_date" label="Pago" render={(record, source) =>
                                    <BooleanField record={{ ...record, pay_date: !!record.pay_date }} source={source} />} />

                                <FunctionField
                                    label="Valor" source="value"
                                    render={record => `R$${record.value}`}
                                />;
                            </Datagrid></ReferenceManyField>
                        <JobRelatedCreateButton record={data} />
                    </Tab>
                    <Tab label="" icon={<DashboardIcon />}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            columnGap: "0.8rem",
                        }}>
                            <CardWithIcon
                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ClientShowSum
                                    queries="count=true" />}
                                subtitle={"Trabalhos"}
                            />
                            <CardWithIcon

                                icon={AssignmentTurnedInOutlinedIcon}
                                title={<ClientShowSum
                                    queries={`count=true&job_start_date_start=${yearBeginStr}`} />}
                                subtitle={"Trabalhos esse ano"}
                            />
                            <CardWithIcon

                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ClientShowSum />}
                                subtitle={"Total recebido"}
                            />
                            <CardWithIcon

                                icon={AccountBalanceWalletOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ClientShowSum
                                    queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`} />}
                                subtitle={"Com NFe esse ano"}
                            />
                            <CardWithIcon

                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ClientShowSum
                                    queries={`job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Últimos 12 meses"}
                            />
                            <CardWithIcon

                                icon={TodayOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ClientShowSum
                                    queries={`job_start_date_start=${yearBeginStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Esse ano"}
                            />
                            <CardWithIcon

                                icon={AccountBalanceOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ClientShowSum
                                    queries={"avg=true"} />}
                                subtitle={"Média por trabalho"}
                            />
                            <CardWithIcon

                                icon={DateRangeOutlinedIcon}
                                titlePrefix={"R$"}
                                title={<ClientShowSum
                                    queries={`avg=true&job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                subtitle={"Média 12 meses"}
                            />
                        </Box>
                        <Box sx={{
                            minHeight: '15rem',
                        }} >
                            <SimpleChart
                                chartTitle="Faturamento R$"
                                mylabels={chartData12Months.chartlabels}
                                myvalues={chartData12Months.chartvalues}
                                tooltipXpre={"Data: "}
                                tooltipYpre={"R$"} />
                        </Box>
                    </Tab>
                </TabbedShowLayout>
            ) :

                isReallyBig ? (
                    <TabbedShowLayout >
                        <Tab label="Principal">
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                columnGap: '2rem',
                            }}>
                                <Box>
                                    <Labeled><TextField source="id" /></Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <TextField source="name" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <TextField source="cnpj" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <EmailField source="email" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <BooleanField source="status" />
                                    </Labeled>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                columnGap: '2rem',
                                rowGap: '1rem',
                            }}>
                                <Box>
                                    <Labeled>
                                        <TextFieldEllipsisMobile source="emails_finance" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <TextFieldEllipsisMobile source="emails_reports" />
                                    </Labeled>
                                </Box>
                            </Box>
                            <Box>
                                <Labeled>
                                    <RichTextField source="description" sx={{ wordWrap: "break-all", whiteSpace: "pre-wrap" }} />
                                </Labeled>
                            </Box>
                            <CreditEmailQuickCreateButton onChange={handleChange} />
                        </Tab>
                        <Tab label="Outros">
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                marginTop: '1rem',
                                columnGap: '2rem',
                            }}>
                                <Box>
                                    <Labeled>
                                        <TextField source="industry" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <NumberField source="discount_period" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <NumberField source="discount_perc" />
                                    </Labeled>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                marginTop: '1rem',
                                columnGap: '2rem',
                            }}>
                                <Box>
                                    <Labeled>
                                        <TextField source="client_type.name" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <TextField source="objective.name" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <DateField source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <DateField source="updated_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                    </Labeled>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                marginTop: '1rem',
                                columnGap: '2rem',
                            }}>
                                <Labeled>
                                    <UrlField sx={{
                                        display: "block",
                                        width: "50rem",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        '&:hover, &:focus, &:active': {
                                            whiteSpace: "normal",
                                            overflow: "visible",
                                            textOverflow: "clip",
                                            wordBreak: "break-all"
                                        }
                                    }} source="hubspot_url" emptyText="Não preenchido" />
                                </Labeled>
                                <Labeled>
                                    <UrlField sx={{
                                        display: "block",
                                        width: "50rem",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        '&:hover, &:focus, &:active': {
                                            whiteSpace: "normal",
                                            overflow: "visible",
                                            textOverflow: "clip",
                                            wordBreak: "break-all"
                                        }
                                    }} source="proposal_url" emptyText="Não preenchido" />
                                </Labeled>
                            </Box>

                        </Tab>
                        <Tab label="Comentários">
                            <ReferenceManyField reference="client_notes" target="client_id" label={false} sort={{ field: "updated_at", order: "DESC" }} >
                                <Datagrid >
                                    <DateField label="Criado em" source="created_at" locales="pt-br" />
                                    <DateField label="Atualizado em" source="updated_at" locales="pt-br" />
                                    <RichTextField stripTags source='note' label="Comentário" sx={{
                                        display: "block",
                                        maxWidth: "150ch",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }} />
                                    <NoteQuickPreviewButton />
                                    <ShowButton label="" />
                                    <EditButton label="" />
                                    <CloneButton label="" />
                                </Datagrid></ReferenceManyField>
                            < NoteRelatedCreateButton />
                        </Tab>

                        <Tab label="Trabalhos">
                            <ReferenceManyField label="Trabalhos" reference="jobs" target="client_id" sort={{ field: "start_date", order: "DESC" }} ><Datagrid>
                                {/* <TextField source="id" /> */}
                                <DateField source="start_date" label="Início" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                <DateField source="expected_pay_date" label="Vencimento" locales="pt-br" options={{ timeZone: 'UTC' }} />

                                <FunctionField source="pay_date" label="Pago" render={(record, source) =>
                                    <BooleanField record={{ ...record, pay_date: !!record.pay_date }} source={source} />} />

                                <FunctionField
                                    label="Valor" source="value"
                                    render={record => `R$${record.value.toLocaleString('pt-br')}`}
                                />;
                                <BooleanField source="is_nfe_issued" label="NFe Emitida" />
                                <DateField source="nfe_date" label="Data NFe" locales="pt-br" />
                                <ArrayField source="types" label="Tipo" ><SingleFieldList>
                                    <ColoredChipField source="name" /></SingleFieldList></ArrayField>
                                <TextField source="status.name" label="Status" />
                                <FunctionField source="report_url" label="Relatório" sortable={false}
                                    render={(record, source) =>
                                        <BooleanField record={{ ...record, report_url: !!record.report_url }} source={source}
                                        />} />
                                <JobQuickPreviewButton />
                                <ShowButton label="" />
                                <EditButton label="" />
                                <CloneButton label="" />
                            </Datagrid></ReferenceManyField>
                            <JobRelatedCreateButton />
                        </Tab>
                        <Tab label="Dashboard">
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                columnGap: "0.8rem",
                            }} >
                                <CardWithIcon
                                    icon={AssignmentTurnedInOutlinedIcon}
                                    title={<ClientShowSum
                                        queries="count=true" />}
                                    subtitle={"Trabalhos"}
                                />
                                <CardWithIcon
                                    icon={AssignmentTurnedInOutlinedIcon}
                                    title={<ClientShowSum
                                        queries={`count=true&job_start_date_start=${yearBeginStr}`} />}
                                    subtitle={"Trabalhos esse ano"}
                                />
                                <CardWithIcon
                                    icon={AccountBalanceOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum />}
                                    subtitle={"Total recebido"}
                                />
                                <CardWithIcon
                                    icon={AccountBalanceWalletOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`} />}
                                    subtitle={"Com NFe esse ano"}
                                />
                                <CardWithIcon
                                    icon={DateRangeOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={`job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                    subtitle={"Últimos 12 meses"}
                                />
                                <CardWithIcon
                                    icon={TodayOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={`job_start_date_start=${yearBeginStr}&job_start_date_end=${todayStr}`} />}
                                    subtitle={"Esse ano"}
                                />
                                <CardWithIcon
                                    icon={AccountBalanceOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={"avg=true"} />}
                                    subtitle={"Média por trabalho"}
                                />
                                <CardWithIcon
                                    icon={DateRangeOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={`avg=true&job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                    subtitle={"Média 12 meses"}
                                />
                            </Box>
                            <Box sx={{
                                minWidth: '50%',
                                maxWidth: '70%',
                                margin: "auto",
                                textAlign: "center",
                            }} >
                                <SimpleChart
                                    chartTitle="Faturamento R$"
                                    mylabels={chartData12Months.chartlabels}
                                    myvalues={chartData12Months.chartvalues}
                                    tooltipXpre={"Data: "}
                                    tooltipYpre={"R$"} />
                            </Box>
                        </Tab>
                    </TabbedShowLayout>

                ) : (

                    <TabbedShowLayout >
                        <Tab label="Principal">
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                columnGap: '2rem',
                            }}>
                                <Box>
                                    <Labeled><TextField source="id" /></Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <TextField source="name" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <TextField source="cnpj" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <EmailField source="email" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <BooleanField source="status" />
                                    </Labeled>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                columnGap: '2rem',
                            }}>
                                <Box>
                                    <Labeled>
                                        <TextFieldEllipsisMobile source="emails_finance" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <TextFieldEllipsisMobile source="emails_reports" />
                                    </Labeled>
                                </Box>
                            </Box>
                            <Box mt='1rem'>
                                <Labeled>
                                    <RichTextField source="description" sx={{ wordWrap: "break-all", whiteSpace: "pre-wrap" }} />
                                </Labeled>
                            </Box>
                            <CreditEmailQuickCreateButton onChange={handleChange} />
                        </Tab>
                        <Tab label="Outros">
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                marginTop: '1rem',
                                columnGap: '2rem',
                            }}>
                                <Box>
                                    <Labeled>
                                        <TextField source="industry" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <NumberField source="discount_period" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <NumberField source="discount_perc" />
                                    </Labeled>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                marginTop: '1rem',
                                columnGap: '2rem',
                            }}>
                                <Box>
                                    <Labeled>
                                        <TextField source="client_type.name" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <TextField source="objective.name" />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <DateField source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled>
                                        <DateField source="updated_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                    </Labeled>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'space-around',
                                flexWrap: 'wrap',
                                marginTop: '1rem',
                            }}>
                                <Labeled>
                                    <UrlField sx={{
                                        display: "block",
                                        width: "50rem",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        '&:hover, &:focus, &:active': {
                                            whiteSpace: "normal",
                                            overflow: "visible",
                                            textOverflow: "clip",
                                            wordBreak: "break-all"
                                        }
                                    }} source="hubspot_url" emptyText="Não preenchido" />
                                </Labeled>
                                <Labeled>
                                    <UrlField sx={{
                                        display: "block",
                                        width: "50rem",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        '&:hover, &:focus, &:active': {
                                            whiteSpace: "normal",
                                            overflow: "visible",
                                            textOverflow: "clip",
                                            wordBreak: "break-all"
                                        }
                                    }} source="proposal_url" emptyText="Não preenchido" />
                                </Labeled>
                            </Box>

                        </Tab>
                        <Tab label="Comentários">
                            <ReferenceManyField reference="client_notes" target="client_id" label={false} sort={{ field: "updated_at", order: "DESC" }} >
                                <Datagrid >
                                    <DateField label="Criado em" source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                    <DateField label="Atualizado em" source="updated_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                    <RichTextField stripTags source='note' label="Comentário" sx={{
                                        display: "block",
                                        maxWidth: "52ch",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }} />
                                    <NoteQuickPreviewButton />
                                    <ShowButton label="" />
                                    <EditButton label="" />
                                    <CloneButton label="" />
                                </Datagrid></ReferenceManyField>
                            < NoteRelatedCreateButton />
                        </Tab>

                        <Tab label="Trabalhos">
                            <ReferenceManyField label="Trabalhos" reference="jobs" target="client_id" sort={{ field: "start_date", order: "DESC" }} ><Datagrid>
                                {/* <TextField source="id" /> */}
                                <DateField source="start_date" label="Início" locales="pt-br" options={{ timeZone: 'UTC' }} />
                                <DateField source="expected_pay_date" label="Vencimento" locales="pt-br" options={{ timeZone: 'UTC' }} />

                                <FunctionField source="pay_date" label="Pago" render={(record, source) =>
                                    <BooleanField record={{ ...record, pay_date: !!record.pay_date }} source={source} />} />

                                <FunctionField
                                    label="Valor" source="value"
                                    render={record => `R$${record.value.toLocaleString('pt-br')}`}
                                />;
                                <BooleanField source="is_nfe_issued" label="NFe Emitida" />
                                <ArrayField source="types" label="Tipo" ><SingleFieldList>
                                    <ColoredChipField source="name" /></SingleFieldList></ArrayField>
                                <TextField source="status.name" label="Status" />
                                <JobQuickPreviewButton />
                                <ShowButton label="" />
                                <EditButton label="" />
                                <CloneButton label="" />
                            </Datagrid></ReferenceManyField>
                            <JobRelatedCreateButton />
                        </Tab>
                        <Tab label="Dashboard">
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                columnGap: "0.8rem",
                            }}>
                                <CardWithIcon
                                    icon={AssignmentTurnedInOutlinedIcon}
                                    title={<ClientShowSum
                                        queries="count=true" />}
                                    subtitle={"Trabalhos"}
                                />
                                <CardWithIcon

                                    icon={AssignmentTurnedInOutlinedIcon}
                                    title={<ClientShowSum
                                        queries={`count=true&job_start_date_start=${yearBeginStr}`} />}
                                    subtitle={"Trabalhos esse ano"}
                                />
                                <CardWithIcon

                                    icon={AccountBalanceOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum />}
                                    subtitle={"Total recebido"}
                                />
                                <CardWithIcon

                                    icon={AccountBalanceWalletOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`} />}
                                    subtitle={"Com NFe esse ano"}
                                />
                                <CardWithIcon

                                    icon={DateRangeOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={`job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                    subtitle={"Últimos 12 meses"}
                                />
                                <CardWithIcon

                                    icon={TodayOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={`job_start_date_start=${yearBeginStr}&job_start_date_end=${todayStr}`} />}
                                    subtitle={"Esse ano"}
                                />
                                <CardWithIcon

                                    icon={AccountBalanceOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={"avg=true"} />}
                                    subtitle={"Média por trabalho"}
                                />
                                <CardWithIcon

                                    icon={DateRangeOutlinedIcon}
                                    titlePrefix={"R$"}
                                    title={<ClientShowSum
                                        queries={`avg=true&job_start_date_start=${yearBeforeStr}&job_start_date_end=${todayStr}`} />}
                                    subtitle={"Média 12 meses"}
                                />
                            </Box>
                            <Box sx={{
                                minWidth: '50%',
                                maxWidth: '70%',
                                margin: "auto",
                                textAlign: "center",
                            }} >
                                <SimpleChart
                                    chartTitle="Faturamento R$"
                                    mylabels={chartData12Months.chartlabels}
                                    myvalues={chartData12Months.chartvalues}
                                    tooltipXpre={"Data: "}
                                    tooltipYpre={"R$"} />
                            </Box>
                        </Tab>
                    </TabbedShowLayout>
                )
            }
        </Show>
    )
}