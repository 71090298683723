import * as React from "react";
import {
    Create, SimpleForm, TextInput, required, maxLength
} from 'react-admin';
import { Box } from '@mui/material';

import validateJobTypeCreation from "./JobTypesValidation"

export const JobTypeModelCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm variant="standard" validate={validateJobTypeCreation} >
            <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    columnGap: '1rem',
                }}>
                    <TextInput label="Nome" source="name"  validate={[required(), maxLength(60)]} />
                    <TextInput label="Cor" source="color"  validate={[required(), maxLength(10)]} />
                </Box>
                <TextInput label="Descrição" fullWidth source="description" />
            </SimpleForm>
        </Create>
    )
};