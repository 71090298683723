import * as React from "react";
import {
    ArrayField, TextField, SingleFieldList, DateField, ReferenceField, BooleanField,
    Show, SimpleShowLayout, UrlField, EmailField, FunctionField, NumberField, Labeled, RichTextField
} from 'react-admin';
import { useMediaQuery, Box } from '@mui/material';
import { ConditionalNfeUrlField } from './NfeUrlField';
import { ColoredChipField } from "../ColoredChips.tsx";
import { ShowActions } from "../Actions"
import { SendFinanceEmailButton } from "./SendFinanceEmailButton";
import { SendReportEmailButton } from "./SendReportEmailButton";

export const JobShow = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Show actions={<ShowActions />} {...props} >
            {isSmall ? (
                <SimpleShowLayout>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <Labeled>
                            <TextField source="id" />
                        </Labeled>
                        <Labeled>
                            <DateField source="start_date" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <DateField source="expected_pay_date" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                        rowGap: '0.5rem',
                    }}>
                        <Labeled>
                            <DateField source="pay_date" emptyText="Pgto pendente" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <DateField source="nfe_date" emptyText="Não emitida" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <FunctionField source="value"
                                render={record => `R$${record.value.toLocaleString('pt-br')}`} />
                        </Labeled>
                    </Box>
                    <ArrayField source="types" >
                        <SingleFieldList linkType={false}>
                            <ColoredChipField source="name" />
                        </SingleFieldList>
                    </ArrayField>
                    <Box mt={1}>
                        <Labeled>
                            <ReferenceField label="Nome do cliente" source="client_id" reference="clients"><TextField source="name" /></ReferenceField>
                        </Labeled>
                    </Box>
                    <RichTextField source="description" />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                        rowGap: '0.5rem',
                    }}>
                        <Labeled>
                            <NumberField source="estimated_hours" emptyText="0" />
                        </Labeled>
                        <Labeled>
                            <FunctionField source="costs"
                                render={record => `R$${record.costs.toLocaleString('pt-br')}`} />
                        </Labeled>
                        <Labeled>
                            <BooleanField label="Nota fiscal emitida" source="is_nfe_issued" defaultValue={false} />
                        </Labeled>
                    </Box>
                    <ConditionalNfeUrlField source="nfe_url" label="" />
                    <UrlField source="boleto_url" emptyText="Não preenchido" target='_blank' />
                    <UrlField source="pix_url" emptyText="Não preenchido" target='_blank' />
                    <UrlField source="report_url" emptyText="Não preenchido" target='_blank' />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                        rowGap: '0.5rem',
                        marginTop: '0.5rem',
                    }}>
                        <Labeled>
                            <ReferenceField source="pay_account.id" reference="payment_account">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                        <Labeled>
                            <ReferenceField source="pay_type.id" reference="payment_type">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                        <Labeled>
                            <ReferenceField source="status.id" reference="job_status">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                        rowGap: '0.5rem',
                    }}>
                        <Labeled>
                            <DateField source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <EmailField source="owner.email" />
                        </Labeled>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                        rowGap: '0.5rem',
                        marginTop: '1.5rem',
                        marginBottom: '1rem',
                    }}>                        
                        <SendFinanceEmailButton />
                        <SendReportEmailButton />
                    </Box>
                </SimpleShowLayout>
            ) : (
                <SimpleShowLayout>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <Labeled>
                            <TextField source="id" />
                        </Labeled>
                        <Labeled>
                            <DateField source="start_date" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <DateField source="expected_pay_date" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <DateField source="pay_date" emptyText="Pgto pendente" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <DateField source="nfe_date" emptyText="Não emitida" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <FunctionField source="value"
                                render={record => `R$${record.value.toLocaleString('pt-br')}`} />
                        </Labeled>
                    </Box>
                    <ArrayField source="types" >
                        <SingleFieldList linkType={false}>
                            <ColoredChipField source="name" />
                        </SingleFieldList>
                    </ArrayField>
                    <Box sx={{ marginTop: '0.5rem' }}>
                        <Labeled>
                            <ReferenceField label="Nome do cliente" source="client_id" reference="clients">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                    </Box>
                    <RichTextField source="description" emptyText='Sem descrição' />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <Labeled>
                            <NumberField source="estimated_hours" emptyText='0' />
                        </Labeled>
                        <Labeled>
                            <FunctionField source="costs"
                                render={record => `R$${record.costs.toLocaleString('pt-br')}`} />
                        </Labeled>
                        <Labeled>
                            <BooleanField label="Nota fiscal emitida" source="is_nfe_issued" defaultValue={false} />
                        </Labeled>
                    </Box>
                    <ConditionalNfeUrlField source="nfe_url" label="" />
                    <UrlField source="boleto_url" emptyText="Não preenchido" target='_blank' />
                    <UrlField source="pix_url" emptyText="Não preenchido" target='_blank' />
                    <UrlField source="report_url" emptyText="Não preenchido" target='_blank' />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <ReferenceField source="pay_account.id" reference="payment_account">
                            <Labeled>
                                <TextField source="name" />
                            </Labeled>
                        </ReferenceField>
                        <ReferenceField source="pay_type.id" reference="payment_type">
                            <Labeled>
                                <TextField source="name" />
                            </Labeled>
                        </ReferenceField>
                        <ReferenceField source="status.id" reference="job_status">
                            <Labeled>
                                <TextField source="name" />
                            </Labeled>
                        </ReferenceField>
                        <Labeled>
                            <DateField source="created_at" locales="pt-br" options={{ timeZone: 'UTC' }} />
                        </Labeled>
                        <Labeled>
                            <EmailField source="owner.email" />
                        </Labeled>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        columnGap: '2rem',
                    }}>
                        <SendFinanceEmailButton />
                        <SendReportEmailButton />
                    </Box>
                </SimpleShowLayout>
            )
            }
        </Show>
    )
}