import * as React from "react";
import {
    List, Datagrid, TextField, EditButton, TextInput, CloneButton, DeleteButton, FilterButton, SortButton, CreateButton, ExportButton, DateInput, TopToolbar, ReferenceField, DateField, SimpleList, RichTextField,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import RecordPagination from "../RecordPagination";

const postFilters = [
    <TextInput label="Busca por nota" source="note" alwaysOn variant="standard" />,
    <DateInput label="Data de criação | De" source="creation_date_start" variant="standard" />,
    <DateInput label="Data de criação | Até " source="creation_date_end" variant="standard" />,
    <DateInput label="Data de atualização | De" source="updated_date_start" variant="standard" />,
    <DateInput label="Data de atualização | Até " source="updated_date_end" variant="standard" />,
];

const ListActions = () => (
    <TopToolbar>
        <SortButton fields={["note", "created_at", "updated_at", "id"]} label="Ordenar" />
        <FilterButton />
        <CreateButton label="ra.action.create_item" />
        <ExportButton />
    </TopToolbar>
);

export const ClientNotesList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List actions={<ListActions />} filters={postFilters} sort={{ field: 'updated_at', order: 'DESC' }} empty={false} pagination={<RecordPagination />} perPage={15} >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={<RichTextField stripTags source='note' label="Comentário" sx={{
                        display: "block",
                        maxWidth: "35ch",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }} />}
                    secondaryText={record => <ReferenceField label="Name" source="client_id" reference="clients" basePath="clients"
                    // Error without link false
                    record={record} link={false} > 
                    <TextField source="name" />
                </ReferenceField>}
                    tertiaryText={record => new Date(record.created_at).toLocaleDateString('pt-br')}
                />
            ) : (
                <Datagrid rowClick="show">
                    {/* <TextField source="id" /> */}
                    <ReferenceField source="client_id" reference="clients" label="Cliente">
                        <TextField source="name" />
                    </ReferenceField>
                    <DateField label="Criado em" source="created_at" locales="pt-br" sortBy="created_at" sortByOrder="DESC" />
                    <DateField label="Atualizado" source="updated_at" locales="pt-br" />
                    <RichTextField stripTags source='note' label="Comentário" sx={{
                        display: "block",
                        maxWidth: "75ch",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }} />
                    <EditButton label="" />
                    <CloneButton label="" />
                    <DeleteButton label="" />
                </Datagrid>
            )}
        </List>
    )
}