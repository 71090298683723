import * as React from "react";
import { useDataProvider, Loading, Error } from 'react-admin';
import { useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';

export const ShowSumId = (props) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const { queries, sumtype, itemtype } = props;

    const { data, isLoading, error } = useQuery(
        [`${sumtype}sum/?${itemtype}=${record.id}&${queries}`, 'getSum', queries, {id: null}],
        () => dataProvider.getSum(`${sumtype}sum/?${itemtype}=${record.id}&${queries}`, {id: null})
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <Error />; }
    if (!data) return null;
    if (data.result === null) return 0;
    return (
        (Math.round((data.result + Number.EPSILON) * 100) / 100).toLocaleString('pt-br')
    )
};

export function ShowGroupSumId(sumtype, itemtype, queries = null, datatype = "", recordId) {
    const dataProvider = useDataProvider();
    const { data, isLoading, error } = useQuery(
        [`${sumtype}group_sum/?${itemtype}=${recordId}&${queries}`, 'getSum', queries, {id: null}],
        () => dataProvider.getSum(`${sumtype}group_sum/?${itemtype}=${recordId}&${queries}`, {id: null})
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <Error />; }
    if (!data) return null;

    if (datatype === "values" && Array.isArray(data.chartvalues) === true) {
        return data.chartvalues;
    } else if (datatype === "labels" && Array.isArray(data.chartlabels) === true) {
        return data.chartlabels;
    }
    else {
        return data
    }
};

ShowSumId.defaultProps = {
    sumtype: "jobs/",
}