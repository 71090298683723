import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';
import { useGetRecordId } from 'react-admin';
import { Box } from '@mui/material';


export const NoteRelatedCreateButton = () => {
    const recordId = useGetRecordId();
    return (
        <Box sx={{
            maxWidth: '10rem',
            marginTop: '1rem',
        }}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<MessageIcon />}
                component={Link}
                state={{ record: { client_id: recordId } }}
                to={{
                    pathname: '/client_notes/create',
                }}
            >
                Adicionar
            </Button>
        </Box>
    )
}; 