import * as React from "react";
import { UrlField, useRecordContext, Labeled } from 'react-admin';

export const ConditionalNfeUrlField = () => {
    const record = useRecordContext();
    return record && record.is_nfe_issued ? <Labeled>
        <UrlField source="nfe_url" target='_blank' />
    </Labeled> : null;
}

