import * as React from "react";
import {
    TextField, Show, SimpleShowLayout, CloneButton, DateField, ReferenceField, Labeled
} from 'react-admin';
import { RichTextField } from 'react-admin';
import { Box } from '@mui/material';

import { ShowActions } from "../Actions"

export const ClientNotesShow = props => {
    return (
        <Show actions={<ShowActions />} {...props}>
            <SimpleShowLayout>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    columnGap: '2rem',
                }}>
                    <Labeled>
                        <ReferenceField source="client_id" reference="clients" label="Cliente"><TextField source="name" /></ReferenceField>
                    </Labeled>
                    <Labeled>
                        <TextField source="id" />
                    </Labeled>
                    <Labeled>
                        <DateField label="Criado em" source="created_at" locales="pt-br" />
                    </Labeled>
                    <Labeled>
                        <DateField label="Atualizado em" source="updated_at" locales="pt-br" />
                    </Labeled>
                </Box>
                <Box>
                    <Labeled>
                        <RichTextField label="Nota" source="note" />
                    </Labeled>
                </Box>
                    <CloneButton variant="outlined" />
                    </SimpleShowLayout>
                </Show>
                )
};