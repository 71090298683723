import * as React from "react";
import { SaveButton, DeleteButton, CloneButton, Toolbar } from 'react-admin';
import { Box } from '@mui/material';


const EditCreateToolbar = props => {
    return (
        <Toolbar {...props} >
            <Box sx={{
                marginRight: '2rem',
            }}>
                <SaveButton
                    label="ra.action.save_and_go_to_list"
                    type="button"
                    disabled={props.pristine}
                />
            </Box>
            <Box sx={{
                marginRight: '3rem',
            }}>
                <CloneButton variant="outlined" />
            </Box>
            <DeleteButton />
        </Toolbar>
    )
};

export default EditCreateToolbar