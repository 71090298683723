import { JobTypeModelList } from "./JobTypesList"
import { JobTypeModelCreate } from "./JobTypesCreate"
import { JobTypeModelEdit } from "./JobTypesEdit"
import { JobTypeModelShow } from "./JobTypesShow"

const JobType = {
    list: JobTypeModelList,
    create: JobTypeModelCreate,
    edit: JobTypeModelEdit,
    show: JobTypeModelShow
};

export default JobType