import * as React from "react";
import { useDataProvider, Loading, Error } from 'react-admin';
import { useQuery } from 'react-query';

export const ValuesSum = (props) => {
    const dataProvider = useDataProvider();
    const { queries, operation } = props;

    const { data, isLoading, error } = useQuery(
        [`jobs/sum/?${queries}`, 'getSum', queries, {id: null}],
        () => dataProvider.getSum(`jobs/sum/?${queries}`, {id: null})
    );


    if (isLoading) { return <Loading />; }
    if (error) { return <Error />; }
    if (!data) return null;
    if (data.result === null) return 0;
    if (operation) {
    return (
        (Math.round(((operation - data.result) + Number.EPSILON) * 100) / 100).toLocaleString('pt-br')
    )}
    else {
        return (
        (Math.round(((data.result) + Number.EPSILON) * 100) / 100).toLocaleString('pt-br'))
    }
};

export function GroupSum(queries = null, datatype) {
    const dataProvider = useDataProvider();
    const { data, isLoading, error } = useQuery(
        ['getSum', queries, {id: null}], 
        () => dataProvider.getSum(`jobs/group_sum/?${queries}`, {id: null})
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <Error />; }
    if (!data) return null;

    if (datatype === "values" && Array.isArray(data.chartvalues) === true) {
        return data.chartvalues;
    } else if (datatype === "labels" && Array.isArray(data.chartlabels) === true) {
        return data.chartlabels;
    }
    else {
        return data
    }
    
    
};

ValuesSum.defaultProps = {
    queries: "",
    operation: null,
  }