import * as React from 'react';
import { ChipField, useRecordContext } from 'react-admin';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const ColoredChipField = ({ ...props }: any) => {
    const record = useRecordContext();
    return (
        record ? (
            <ChipField
                record={record}
                {...props}
                style={{ backgroundColor: record.color, border: 0, marginTop: "0.7rem" }}
                component="span"
                size="small"
                icon={<AssignmentIcon />}
                color='primary'
            />
        ) : null
    )
}