import * as React from "react";
import { Admin, Resource, Title, useTheme } from 'react-admin';
import authProvider from "./authProvider";
import Clients from './resources/clients/Clients';
import Jobs from './resources/jobs/Jobs';
import BaseModel from './resources/basemodel/BaseModel';
import ClientNotes from './resources/clientnotes/ClientNotes';
import JobType from './resources/jobtypes/JobType';

import CreditEmailCreate from './resources/creditemails/CreditEmailCreate';
import OtherEmailsCreate from './resources/otheremails/OtherEmailsCreate';
import WordpressSendinCreate from './resources/wpsendin/WpSendinCreate';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import myDataProvider from './myDataProvider';
import MyLayout from './layout/MyLayout';
import LoginPage from './layout/LoginPage';
import { Dashboard } from './dashboard/Dashboard';
import { i18nProvider } from './translation/i18nProvider';
import { QueryClient } from 'react-query';

const dataProvider = myDataProvider;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5 minutes
        },
    },
});

const App = () => {
    const [theme,] = useTheme()

    return (
        <Admin theme={theme} dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider}
            dashboard={Dashboard} loginPage={LoginPage} layout={MyLayout} queryClient={queryClient} >
            <Title title="Controle - " />
            <Resource name="clients" {...Clients} icon={PersonOutlineOutlinedIcon} />
            <Resource name="client_notes" {...ClientNotes} options={{ label: 'Comentários' }} icon={ChatOutlinedIcon} />
            <Resource name="jobs" {...Jobs} icon={AssignmentTurnedInOutlinedIcon} />
            <Resource name="client_objective" {...BaseModel} icon={AdjustOutlinedIcon} />
            <Resource name="client_type" {...BaseModel} icon={PermContactCalendarOutlinedIcon} />
            <Resource name="job_status" {...BaseModel} icon={ToggleOffOutlinedIcon} />
            <Resource name="job_type" {...JobType} icon={TimelineIcon} />
            <Resource name="payment_account" {...BaseModel} icon={AccountBalanceOutlinedIcon} />
            <Resource name="payment_type" {...BaseModel} icon={CreditCardOutlinedIcon} />
            <Resource name="send_email/credit" options={{ label: 'E-mail de créditos ', url: '/send_email/credit/create' }} create={CreditEmailCreate} icon={EmailOutlinedIcon} />
            <Resource name="send_email/credit" options={{ label: 'E-mail de créditos ', url: '/send_email/credit/create' }} create={CreditEmailCreate} icon={EmailOutlinedIcon} />
            <Resource name="send_email/other_emails" options={{ label: 'Outros e-mails ', url: '/send_email/other_emails/create' }} create={OtherEmailsCreate} icon={SendOutlinedIcon} />
            <Resource name="wordpress_sendin/user_client_contact" options={{ label: 'Contatos e Usuários ', url: '/wordpress_sendin/user_client_contact/create' }} create={WordpressSendinCreate} icon={ContactMailOutlinedIcon} />
        </Admin>
    )
};

export default App;