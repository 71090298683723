import * as React from "react";
import {
    List, Datagrid, TextField, EmailField, TextInput, EditButton, CloneButton, DeleteButton, TopToolbar, SortButton, FilterButton,
    CreateButton, ExportButton, SimpleList, BooleanField, BooleanInput
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
// import { ImportButton } from "react-admin-import-csv";
// import importConfig from "../../data/ImportConfig";
import RecordPagination from "../RecordPagination";

const ListActions = (props) => (
    <TopToolbar>
        <SortButton fields={['name', 'industry', 'status',
            'created_at', 'updated_at', 'objective__name', "client_type__name"]} />
        <FilterButton />
        <CreateButton />
        <ExportButton />
        {/* <ImportButton {...props} {...importConfig} /> */}
    </TopToolbar>
);

const postFilters = [
    <TextInput label="Busca por nome" source="name" alwaysOn variant="standard" />,
    <TextInput label="Descrição" source="description" variant="standard" />,
    <TextInput label="e-mail" source="email" variant="standard" />,
    <TextInput label="CNPJ" source="cnpj" variant="standard" />,
    <TextInput label="Objetivo" source="objective" variant="standard" />,
    <BooleanInput label="Status" source="status" variant="standard" alwaysOn />,
];

export const ClientsList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isReallyBig = useMediaQuery('(min-width:1921px)')
    return (
        <List actions={<ListActions />} filters={postFilters} filterDefaultValues={{ status: true }}
            {...props} sort={{ field: 'created_at', order: 'DESC' }} empty={false} pagination={<RecordPagination />} perPage={15} >
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => record.name}
                    secondaryText={record => record.client_type.name}
                    tertiaryText={record => (record.objective.name)}
                />
            ) :
                isReallyBig ? (
                    <Datagrid rowClick="show">
                        {/* <TextField source="id" /> */}
                        <TextField source="name" label="Nome" />
                        <TextField source="cnpj" label="CNPJ" />
                        <EmailField source="email" />
                        <TextField source="industry" />
                        <TextField label="Tipo" source="client_type.name" />
                        <TextField label="Objetivo" source="objective.name" />
                        <BooleanField source="status"  />
                        <EditButton label="" />
                        <CloneButton label="" />
                        <DeleteButton label="" />
                    </Datagrid>
                ) : (
                    <Datagrid rowClick="show">
                        {/* <TextField source="id" /> */}
                        <TextField source="name" label="Nome" />
                        {/* <TextField source="cnpj" label="CNPJ" /> */}
                        <EmailField source="email" />
                        {/* <TextField source="industry" /> */}
                        <TextField label="Tipo" source="client_type.name" />
                        {/* <TextField label="Objetivo" source="objective.name" /> */}
                        <BooleanField source="status" />
                        <EditButton label="" />
                        <CloneButton label="" />
                        <DeleteButton label="" />
                    </Datagrid>
                )
            }
        </List>
    )
}