import * as React from "react";
import { useAuthenticated } from 'react-admin';
import { Box, Card } from '@mui/material';
import { ValuesSum, GroupSum } from '../data/ValuesSum'

import CardWithIconForDash from "../layout/CardWithIconForDash.js";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

import { yearBeginStr, yearBeforeStr, beginMonthBefore, endMonthBefore, beginCurrentMonth, endCurrentMonth } from "../data/Dates"

import { SimpleChart } from "../charts/SimpleChart"
import FixedData from "../data/FixedData.json"


export const Dashboard = () => {
    useAuthenticated(); // redirects to login if not authenticated
    const chartDataThisYear = GroupSum(`by_month=true&job_start_date_start=${yearBeforeStr}`)
    const chartDataThisYearCount = GroupSum(`by_month=true&count=true&job_start_date_start=${yearBeforeStr}`)


    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
            }}>
                <CardWithIconForDash
                    icon={AttachMoneyOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`pay_date_start=${beginCurrentMonth}&pay_date_end=${endCurrentMonth}`} />}
                    subtitle={"Já pagos esse mês"}
                />
                <CardWithIconForDash
                    icon={MoneyOffOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`expected_pay_date_start=${beginCurrentMonth}&expected_pay_date_end=${endCurrentMonth}&pay_date=false`} />}
                    subtitle={"Ainda não pagos esse mês"}
                />
                <CardWithIconForDash
                    icon={AccountBalanceOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`pay_date_start=${beginMonthBefore}&pay_date_end=${endMonthBefore}`} />}
                    subtitle={"Recebido mês passado"}
                />
                <CardWithIconForDash
                    icon={MoneyOffOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`pay_date=false`} />}
                    subtitle={"Total a receber"}
                />
                <CardWithIconForDash
                    icon={AccountBalanceWalletOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`} />}
                    subtitle={"Com NFe esse ano"}
                />
                <CardWithIconForDash
                    icon={AccountBalanceWalletOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`with_nfe=true&job_nfe_date_start=${yearBeginStr}`}
                        operation={FixedData.limiteMei} />}
                    subtitle={"Falta para limite MEI"}
                />
                <CardWithIconForDash
                    icon={AssignmentTurnedInOutlinedIcon}
                    title={<ValuesSum
                        queries={`count=true&job_start_date_start=${yearBeginStr}`} />}
                    subtitle={"Trabalhos esse ano"}
                />
                <CardWithIconForDash
                    icon={AccountBalanceOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`job_start_date_start=${yearBeginStr}`} />}
                    subtitle={"Faturamento esse ano"}
                />
                <CardWithIconForDash
                    icon={AccountBalanceOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`job_start_date_start=${yearBeforeStr}`} />}
                    subtitle={"Faturamento 12 meses"}
                />
                <CardWithIconForDash
                    icon={AssignmentTurnedInOutlinedIcon}
                    title={<ValuesSum
                        queries={`count=true&job_start_date_start=${yearBeforeStr}`} />}
                    subtitle={"Trabalhos 12 meses"}
                />
                <CardWithIconForDash
                    icon={AssignmentTurnedInOutlinedIcon}
                    title={<ValuesSum
                        queries={`count=true`} />}
                    subtitle={"Trabalhos"}
                />
                <CardWithIconForDash
                    icon={AccountBalanceOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum />}
                    subtitle={"Faturamento total"}
                />
                <CardWithIconForDash
                    icon={AccountBalanceWalletOutlinedIcon}
                    titlePrefix={"R$"}
                    title={<ValuesSum
                        queries={`with_nfe=true`} />}
                    subtitle={"Com NFe"}
                />
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
            }}>
                <Card sx={{ minWidth: '20rem', maxWidth: '35rem', flexShrink: 2, flexGrow: 2, marginLeft: '1rem', marginTop: '1rem'}} >
                    <SimpleChart
                        chartTitle="Faturamento R$"
                        mylabels={chartDataThisYear.chartlabels}
                        myvalues={chartDataThisYear.chartvalues}
                        tooltipXpre={"Data: "}
                        tooltipYpre={"R$"} />
                </Card>
                <Card sx={{ minWidth: '20rem', maxWidth: '35rem', flexShrink: 2, flexGrow: 2, marginLeft: '1rem', marginTop: '1rem'}} >
                    <SimpleChart
                        chartTitle="Número de trabalhos"
                        mylabels={chartDataThisYearCount.chartlabels}
                        myvalues={chartDataThisYearCount.chartvalues}
                        tooltipXpre={"Data: "}
                        tooltipYpre={"Trabalhos: "} />
                </Card>
            </Box>

            {/* <ChartWithTwoDataSets
                mylabels={chartDataThisYear.chartlabels || [0, 0]}
                myvalues={chartDataThisYear.chartvalues || [0, 0]}
                myvalues2={chartNFeDataThisYear.chartvalues || [0, 0]}
                tooltipXpre={"Data: "}
                tooltipYpre={"R$"} 
                data1Name="Recebido"
                data2Name="Com nota" 
                chartType2="line" /> */}
        </>
    )
};

export default Dashboard