import {
    TextField
} from 'react-admin';
import { styled } from '@mui/material/styles';

const TextFieldEllipsisMobile = styled(TextField)({
        display: "block",
        width: "20rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '&:hover, &:focus, &:active': {
            whiteSpace: "normal",
            overflow: "visible",
            textOverflow: "clip",
            wordBreak: "break-word"
        }
});

export default TextFieldEllipsisMobile