import { BaseModelList } from "./BaseModelList"
import { BaseModelCreate } from "./BaseModelCreate"
import { BaseModelEdit } from "./BaseModelEdit"
import { BaseModelShow } from "./BaseModelShow"

const BaseModel = {
    list: BaseModelList,
    create: BaseModelCreate,
    edit: BaseModelEdit,
    show: BaseModelShow
};

export default BaseModel