import * as React from "react";
import {
    TextInput, Create, SimpleForm, ReferenceInput, AutocompleteInput, Toolbar,
    SaveButton, SelectInput, required, useNotify, useRefresh
} from 'react-admin';
import { Box } from '@mui/material';
import Send from '@mui/icons-material/Send';
import validateCreditEmailCreation from "./CreditEmailValidate"
import FixedData from "../../data/FixedData.json"

const CustomToolbar = props => (
    <Toolbar >
        <SaveButton label="ENVIAR" variant="contained" icon={<Send />} />
    </Toolbar>
)

const redirect = (id, data) => `/clients/${data.client_id}/show/1`

export const CreditEmailCreate = props => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify(`ra.notification.send_email`, { type: 'success' });
        refresh();
    }

    const onFailure = () => {
        notify(`ra.notification.send_email_fail`, { type: 'error' });
        refresh();
    }

    return (

        <Create {...props} title="Enviar e-mail de créditos" redirect={redirect}
            mutationOptions={{
                onSuccess: onSuccess,
                onError: onFailure
            }}>
            <SimpleForm variant="standard" toolbar={<CustomToolbar />} validate={validateCreditEmailCreation} >
                <h2>Envio de emails de crédito de plataformas Ads</h2>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'space-around',
                    flexWrap: 'wrap',
                    columnGap: '1rem',
                }}>
                    <ReferenceInput source="client_id" reference="clients"
                        filterToQuery={searchText => ({ name: searchText })} // enableGetChoices={({ name }) => name?.length >= 2}
                        filter={{ status: true }} >
                        <AutocompleteInput label="Nome do cliente" optionText="name" validate={required()}
                            sx={{ minWidth: '15rem' }} />
                    </ReferenceInput>
                    <SelectInput source="template_id" label="Tipo de e-mail" optionValue="template_id"
                        validate={required()} choices={FixedData.choices_for_credit_email}
                        sx={{ minWidth: '15rem' }} />
                </Box>
                <TextInput source="url" label="URL do boleto" fullWidth />
            </SimpleForm>
        </Create>
    )
};

// http://localhost:3000/#/send_email/credit/create

export default CreditEmailCreate