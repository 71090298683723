import React from 'react';
import { Line } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';



export function SimpleChart(props) {
    defaults.color = "#BDBDBD";
    defaults.plugins.tooltip.titleColor = "#EFC44F";
    const { mylabels, myvalues, chartType, chartTitle, hide } = props
    const data = {
        labels: mylabels,
        datasets: [
            {
                type: chartType,
                label: chartTitle,
                data: myvalues,
                fill: true,
                borderColor: "#EFC44F",
                backgroundColor: "rgba(239, 196, 79, 0.1)",
                pointBackgroundColor: "#EFC44F",
                pointBorderColor: "#FFFF",
                pointHoverBackgroundColor: "#EFC44F",
                pointHoverBorderColor: "#EFC44F",
            },
        ],
    };

    const options = {
        // scales: {
        //     y: {
        //         beginAtZero: false,
        //         title: {
        //             display: true,
        //             text: "TESTE Y",
        //             font: {
        //                 size: 15
        //             }
        //         },
        //         ticks: {
        //             precision: 0
        //         }
        //     },
        //     x: {
        //         title: {
        //             display: true,
        //             text: "TESTEX",
        //             font: {
        //                 size: 15
        //             }
        //         }
        //     }
        // },
        // plugins: {
        //     legend: {
        //         display: false,
        //     }
        // }
    };
    if (hide === false) {
        return (
            <>
                <Line data={data} options={options} />
            </>
        )
    }
    else {
        return null
    }
};

SimpleChart.defaultProps = {
    chartType: "line",
    mylabels: [0, 0],
    myvalues: [0, 0],
    tooltipXpre: "",
    tooltipYpre: "",
    chartTitle: "",
    hide: false
}