import {
	RichTextInput,
	RichTextInputToolbar,
	LevelSelect,
	FormatButtons,
	ListButtons,
	LinkButtons,
	// QuoteButtons,
	ClearButtons,
} from 'ra-input-rich-text';

export const RichTextSmallToolbar = ({ size, ...props }) => (
	<RichTextInput
		toolbar={
			<RichTextInputToolbar>				
				<FormatButtons size={size} />
				<ListButtons size={size} />
				<LinkButtons size={size} />				
				<ClearButtons size={size} />
                <LevelSelect size={size} />
                {/* <QuoteButtons size={size} /> */}
			</RichTextInputToolbar>
		}
		label="Body"
		source="body"
		{...props}
	/>
);