import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { Button, SimpleShowLayout, DateField, RichTextField } from 'react-admin';



const NoteQuickPreviewButton = ({ record, id }) => {
    const [showPanel, setShowPanel] = useState(false);

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };

    return (
        <>
            <Button onClick={handleClick} label="Preview">
                <VisibilityOutlinedIcon />
            </Button>
            <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
                <div>
                    <Button label="ra.action.close" onClick={handleCloseClick}>
                        <HighlightOff />
                    </Button>
                </div>
                <SimpleShowLayout
                    basePath="/client_notes"
                    resource="client_notes"
                >
                    <DateField source="created_at" locales="pt-br" />
                    <DateField source="updated_at" locales="pt-br" />
                    <RichTextField source="note" label="Comentário" />
                </SimpleShowLayout>
            </Drawer>
        </>
    );
};

export default NoteQuickPreviewButton;
