import * as React from "react";
import {
    TextInput, NumberInput, BooleanInput, FormTab, TabbedForm, Create, ReferenceInput, SelectInput, required, email, number, minValue, maxLength,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { RichTextSmallToolbar } from '../../layout/RichTextSmallToolbar';

import validateClientCreation from "./ClientValidation"
import { Box } from '@mui/material';
const validateReqEmail = [required(), email()]
const validateMinNumbers = [number(), minValue(0)]
const validateUrlsLength = [maxLength(120)]


export const ClientsCreate = props => {
    return (
        <Create {...props}>
            <TabbedForm warnWhenUnsavedChanges variant="standard" validate={validateClientCreation}>
                <FormTab label="Principal"
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        columnGap: '1rem',
                        wordBreak: "break-word", 
                        minWidth: '4rem',
                        width: '100%',
                        display: 'flex',
                    }} >
                    <Box>
                        <TextInput label="Nome" source="name"
                            fullWidth />
                    </Box>
                    <Box>
                        <TextInput label="CNPJ" source="cnpj" />
                    </Box>
                    <Box>
                        <TextInput label="e-mail" source="email" placeholder="e-mail principal de contato" validate={validateReqEmail} />
                    </Box>
                    <Box>
                        <TextInput source="emails_finance" placeholder="lista de e-mails separada por ;" />
                    </Box>
                    <Box>
                        <TextInput source="emails_reports" placeholder="lista de e-mails separada por ;"
                        />
                    </Box>
                    <Box>
                        <BooleanInput label="Status" source="status" defaultValue={true}
                        />
                    </Box>

                    <Box sx={{
                        width: '100%',
                        display: { xs: 'flex', sm: 'flex', lg: 'none', xl: 'none' },
                        paddingBottom: '5rem',
                        wordBreak: "break-word",
                    }} >
                        <RichTextSmallToolbar size={'small'} label={false} source="description" fullWidth helperText="Descrição principal do cliente" />
                    </Box>

                    <Box sx={{
                        width: '100%',
                        display: { xs: 'none', sm: 'none', lg: 'inline', xl: 'inline' },
                        wordBreak: "break-word",
                    }} >
                        <RichTextInput label={false} source="description" fullWidth helperText="Descrição principal do cliente" />
                    </Box>

                </FormTab>

                <FormTab label="Outros">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'space-around',
                        flexWrap: 'wrap',
                        columnGap: '1rem',
                    }}>
                        <Box>
                            <TextInput label="Ramo/Setor" source="industry" />
                        </Box>
                        <Box>
                            <NumberInput label="Período de desconto" source="discount_period" defaultValue="3"
                                validate={validateMinNumbers} />
                        </Box>
                        <Box>
                            <NumberInput label="% de desconto" source="discount_perc" defaultValue="25"
                                validate={validateMinNumbers} />
                        </Box>

                        <Box>
                            <ReferenceInput source="client_type.id" reference="client_type"
                            >
                                <SelectInput label="Tipo de cliente" optionText="name" validate={[required()]} /></ReferenceInput>
                        </Box>
                        <Box>
                            <ReferenceInput source="objective.id" reference="client_objective"
                            >
                                <SelectInput label="Objetivo principal" optionText="name" validate={[required()]} /></ReferenceInput>
                        </Box>
                    </Box>
                    <Box>
                        <TextInput label="URL do Hubspot" source="hubspot_url" validate={validateUrlsLength} />
                    </Box>
                    <Box>
                        <TextInput label="URL da proposta" source="proposal_url" validate={validateUrlsLength} />
                    </Box>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};