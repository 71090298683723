import React, { useState } from 'react';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    useNotify,
    Form,
    useGetRecordId,
    CreateBase,
} from 'react-admin';
import { Box } from '@mui/material';
import Send from '@mui/icons-material/Send';
import IconCancel from '@mui/icons-material/Cancel';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import FixedData from "../../data/FixedData.json"


function CreditEmailQuickCreateButton() {
    const [showDialog, setShowDialog] = useState(false);

    const recordId = useGetRecordId()
    const postDefaultValue = () => ({ client_id: recordId });

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const notify = useNotify();

    const SendButton = props => {       
    
        return <SaveButton type="button" {...props} label="ra.action.send"
            startIcon={<Send />} 
            mutationOptions={{
                onSuccess: () => {
                    notify('ra.notification.send_email', { type: 'success' });                    
                    handleCloseClick()                    
                },
                onFailure: (error) => { notify(`Erro no envio: ${error.message}`, { type: 'error' }) }
            }} />;
    };

    return (
        <>
            <Box sx={{
                maxWidth: '15rem',
                marginTop: '3rem',     
                marginBottom: '1rem',           
            }}>
                <Button onClick={handleClick} label="ra.action.send_credit_email"
                    variant="contained"
                    color="primary"
                    startIcon={<Send />} 
                    sx={{
                        minHeight: '2.5rem',
                    }}
                                        />
            </Box>
            
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="ra.action.send_credit_email"
            >
                <DialogTitle>Enviar e-mail de crédito</DialogTitle>
                <CreateBase resource='send_email/credit'>
                    <Form defaultValues={postDefaultValue} 
                    >
                        <DialogContent>
                            <ReferenceInput source="client_id" reference="clients">
                                <AutocompleteInput  label="Nome do cliente" optionText="name" disabled /></ReferenceInput>
                            <SelectInput source="template_id" label="Tipo de e-mail" optionValue="template_id" validate={[required()]}
                                choices={FixedData.choices_for_credit_email} />
                            <TextInput source="url" label="URL do boleto" fullWidth />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                label="ra.action.cancel"
                                onClick={handleCloseClick}
                            >
                                <IconCancel />
                            </Button>
                            <SendButton/>
                        </DialogActions>
                    </Form>
                </CreateBase>
            </Dialog>
        </>
    );
}

export default CreditEmailQuickCreateButton;
