import * as React from "react";
import {
    Create, SimpleForm, TextInput, required, maxLength
} from 'react-admin';
import validateBaseCreation from "./BaseModelValidation"


export const BaseModelCreate = () => {
    return (
        <Create>
            <SimpleForm variant="standard" validate={validateBaseCreation} >
                <TextInput label="Nome" source="name" validate={[required(), maxLength(60)]} />
                <TextInput label="Descrição" fullWidth source="description" />
            </SimpleForm>
        </Create>
    )
};