import * as React from "react";
import { useDataProvider, Loading, Error } from 'react-admin';
import { useRecordContext } from 'react-admin';
import { useQuery } from 'react-query';

export const ClientShowSum = (props) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const { queries } = props;

    const { data, isLoading, error } = useQuery(
        [`jobs/sum/?for_client=${record.id}&${queries}`, 'getSum', {id: null}],
        () => dataProvider.getSum(`jobs/sum/?for_client=${record.id}&${queries}`, 'getSum', {id: null})
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <Error />; }
    if (!data) return null;
    if (data.result === null) return 0;
    return (
        (Math.round((data.result + Number.EPSILON) * 100) / 100).toLocaleString('pt-br')
    )
};

export function ClientShowGroupSum(queries = null, datatype = "", recordId) {
    const dataProvider = useDataProvider();
    // const record = useRecordContext();
    const { data, isLoading, error } = useQuery(
        [`jobs/group_sum/?for_client=${recordId}&${queries}`, 'getSum', {id: null}],
        () => dataProvider.getSum(`jobs/group_sum/?for_client=${recordId}&${queries}`, 'getSum', {id: null})
    );

    if (isLoading) { return <Loading />; }
    if (error) { return <Error />; }
    if (!data) return null;

    if (datatype === "values" && Array.isArray(data.chartvalues) === true) {
        return data.chartvalues;
    } else if (datatype === "labels" && Array.isArray(data.chartlabels) === true) {
        return data.chartlabels;
    }
    else {
        return data
    }
};