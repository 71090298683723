import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import HighlightOff from '@mui/icons-material/HighlightOff';
import {
    Button, SimpleShowLayout, TextField, ReferenceField, BooleanField, DateField, UrlField,
    ArrayField, ChipField, SingleFieldList, FunctionField, NumberField, RichTextField
} from 'react-admin';
import { ConditionalNfeUrlField } from '../jobs/NfeUrlField';


const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            // backgroundColor: color,
            borderColor: color,
            width: '10rem',
            height: 2,
            opacity: 0.1
        }}
    />
);

const JobQuickPreviewButton = ({ id }) => {
    const [showPanel, setShowPanel] = useState(false);

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };

    return (
        <>
            <Button onClick={handleClick} label="Preview">
                <VisibilityOutlinedIcon />
            </Button>
            <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
                <div>
                    <Button label="ra.action.close" onClick={handleCloseClick}>
                        <HighlightOff />
                    </Button>
                </div>
                <SimpleShowLayout
                    basePath="/jobs"
                    resource="jobs"
                >
                    {/* <TextField source="id" /> */}
                    <DateField label="Data de início" source="start_date" locales="pt-br" options={{ timeZone: 'UTC' }} />
                    <DateField label="Vencimento" source="expected_pay_date" locales="pt-br" options={{ timeZone: 'UTC' }} />
                    <ColoredLine />
                    <DateField label="Data do pagamento" source="pay_date" emptyText="Pgto pendente" locales="pt-br" options={{ timeZone: 'UTC' }} />
                    <DateField source="nfe_date" locales="pt-br" options={{ timeZone: 'UTC' }} />
                    <FunctionField source="value" 
                        render={record => `R$${record.value.toLocaleString('pt-br')}`}/>
                    <ArrayField label="Tipo do trabalho" source="types" ><SingleFieldList>
                        <ChipField label={false} source="name" />
                    </SingleFieldList></ArrayField>
                    <ReferenceField label="Nome do cliente" source="client_id" reference="clients"><TextField source="name" /></ReferenceField>
                    <RichTextField label="Descrição" source="description"  />
                    <FunctionField source="costs"
                        render={record => `R$${record.costs.toLocaleString('pt-br')}`} />
                    <BooleanField label="NFe Emitida" source="is_nfe_issued" defaultValue={false} />
                    <NumberField label="Horas estimadas" source="estimated_hours" />
                    <ConditionalNfeUrlField source="nfe_url" emptyText="Sem url" target='_blank' />
                    <UrlField label="URL do boleto" source="boleto_url" emptyText="Não preenchido" target='_blank' />
                    <UrlField label="URL do PIX" source="pix_url" emptyText="Não preenchido" target='_blank' />
                    <UrlField label="URL do Relatório" source="report_url" emptyText="Não preenchido" target='_blank' />
                    <TextField label="Conta de pagamento" source="pay_account.name" />
                    <TextField label="Tipo do pagamento" source="pay_type.name" />
                    <ColoredLine />
                    <TextField label="Status do trabalho" source="status.name" />
                    <DateField label="Criado em" source="created_at" locales="pt-br" />
                    {/* <EmailField label="Proprietário do registro" source="owner.email" /> */}
                </SimpleShowLayout>
            </Drawer>
        </>
    );
};

export default JobQuickPreviewButton;
