import * as React from 'react';
import Typography from '@mui/material/Typography';
import { AppBar, ToggleThemeButton } from 'react-admin';
import { darkTheme, lightTheme } from './CustomTheme';
import { Box } from '@mui/material';

let Logo = 'https://diegogaona.com/wp-content/uploads/2020/04/logo-diegogaonacom.svg'


const MyAppBar = props => {
    const theme = darkTheme

    return (
        <AppBar
            sx={{
                "& .RaAppBar-title": {
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    color: theme.palette.primary.main,
                },
            }}
            enableColorOnDark={true}
            {...props}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: { xs: '100%', sm: '80%', lg: '90%' },
                justifyContent: 'space-between'
            }}>
                <Box sx={{
                    alignSelf: 'center',
                }}>
                    <Typography
                        variant="h6"
                        color="inherit"
                        id="react-admin-title"
                    />
                </Box>

                <Box sx={{
                    maxWidth: { xs: '0', sm: '100px', md: '125px' },
                    alignSelf: 'center',
                    // visibility: { xs: 'hidden', sm: 'visible' },
                    // visibility: isSmall ? 'hidden' : 'visible'
                }}>
                    <img src={Logo}
                        style={{
                            width: "85%",
                        }}
                        alt="diegogaona.com" />
                </Box>
                <ToggleThemeButton
                    lightTheme={lightTheme}
                    darkTheme={darkTheme}
                />
            </Box>
        </AppBar>
    )
}

export default MyAppBar