import { ClientNotesList } from "./ClientNotesList"
import { ClientNotesCreate } from "./ClientNotesCreate"
import { ClientNotesEdit } from "./ClientNotesEdit"
import { ClientNotesShow } from "./ClientNotesShow"

const ClientNotes = {
    list: ClientNotesList,
    create: ClientNotesCreate,
    edit: ClientNotesEdit,
    show: ClientNotesShow
};

export default ClientNotes