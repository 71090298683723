const validateJobTypeCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'ra.validation.nameRequired';
    }
    else if (values.name.length > 60) {
        errors.name = {
            message: 'ra.validation.maxLength',
            args: { max: 60 }            
        };
    }
    return errors
};

export default validateJobTypeCreation