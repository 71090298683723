import polyglotI18nProvider from 'ra-i18n-polyglot';
// import en from 'ra-language-english';
// import * as domainMessages from "react-admin-import-csv/lib/i18n";
import ptBrMessages from './TranslationPtBr';


const messages = {
    'ptBR': ptBrMessages,
  };

// const translations = { en, ptBR };

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'ptBR');